import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const GrowthTab = ({ data }) => {
  // Ensure we have default values with consistent field names
  const {
    daily_signups = [],
    sources = [],
    total_signups = 0,
    email_metrics = {
      overall: { total_signups: 0, confirmed_emails: 0, confirmation_rate: 0 },
      period: { total_signups: 0, confirmed_emails: 0, confirmation_rate: 0 },
      daily_confirmations: []
    }
  } = data || {};

  const combinedDailyData = daily_signups.map(signup => {
    const confirmations = email_metrics.daily_confirmations.find(
      conf => conf.date === signup.date
    );
    return {
      date: signup.date,
      signups: signup.count,
      confirmations: confirmations ? confirmations.count : 0
    };
  });

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>User Growth & Email Confirmations</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart 
                data={combinedDailyData}
                margin={{ top: 20, right: 30, left: 60, bottom: 40 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => new Date(date).toLocaleDateString()}
                  label={{ value: 'Date', position: 'bottom', offset: 0 }}
                />
                <YAxis 
                  label={{ value: 'Count', angle: -90, position: 'left', offset: 10 }}
                />
                <Tooltip 
                  labelFormatter={(date) => new Date(date).toLocaleDateString()}
                  formatter={(value, name) => [
                    `${value} ${name}`, 
                    name === 'signups' ? 'New Signups' : 'Email Confirmations'
                  ]}
                />
                <Line 
                  type="monotone" 
                  dataKey="signups" 
                  stroke="#2563eb" 
                  strokeWidth={2}
                  dot={false}
                  name="signups"
                />
                <Line 
                  type="monotone" 
                  dataKey="confirmations" 
                  stroke="#16a34a" 
                  strokeWidth={2}
                  dot={false}
                  name="confirmations"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Sign Up Sources</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {sources.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Source
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Users
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        %
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sources.map((source) => (
                      <tr key={source.source}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {source.source}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {source.count}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {source.percentage}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center py-4 text-gray-500">
                  No source data available
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        <div className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Period Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                <div>
                  <div className="text-sm font-medium text-gray-500">New Signups</div>
                  <div className="mt-1 text-2xl font-semibold">
                    {email_metrics.period.total_signups}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-500">Confirmed Emails</div>
                  <div className="mt-1 text-2xl font-semibold">
                    {email_metrics.period.confirmed_emails}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-500">Period Confirmation Rate</div>
                  <div className="mt-1 text-2xl font-semibold">
                    {email_metrics.period.confirmation_rate}%
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Overall Email Status</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                <div>
                  <div className="text-sm font-medium text-gray-500">Total Signups</div>
                  <div className="mt-1 text-2xl font-semibold">
                    {email_metrics.overall.total_signups}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-500">Total Confirmed</div>
                  <div className="mt-1 text-2xl font-semibold">
                    {email_metrics.overall.confirmed_emails}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-500">Overall Confirmation Rate</div>
                  <div className="mt-1 text-2xl font-semibold">
                    {email_metrics.overall.confirmation_rate}%
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default GrowthTab;