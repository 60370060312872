// src/routes/adminRoutes.jsx
import { AdminPanel, ModelManager, TaskManager, SignupAnalytics, NotificationManager } from '../components/admin';
import ProtectedSuperUserRoute from '../components/auth/ProtectedSuperUserRoute';

export const adminRoutes = [
  {
    path: '/dashboard/admin',
    element: (
      <ProtectedSuperUserRoute>
        <AdminPanel />
      </ProtectedSuperUserRoute>
    ),
    children: [
      { path: '', element: <ModelManager /> },
      { path: 'tasks', element: <TaskManager /> },
      { path: 'analytics', element: <SignupAnalytics /> },
      { path: 'notifications', element: <NotificationManager /> }
    ]
  }
];