import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Messages from './components/Messages';
import { AuthContext } from './auth/authcontext';
import UtmCapture from './components/UtmCapture';

const App = () => {
  const { isAuthenticated, isLoading, user } = useContext(AuthContext);

  return (
    <div className="flex flex-col min-h-screen">
      <UtmCapture />
      <Header />
      <Messages />
      <main className="flex-grow">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default App;
