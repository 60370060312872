import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../auth/authcontext';
import { useMessage } from '../../services/MessageService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function AccountDeletion() {
  const { token, logout } = useContext(AuthContext);
  const { addMessage } = useMessage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deletionStatus, setDeletionStatus] = useState(null);
  const [password, setPassword] = useState('');

  // Fetch current deletion status
  const fetchDeletionStatus = async () => {
    try {
      const response = await axios.get('/api/auth/delete-account/', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setDeletionStatus(response.data);
      
      // If account is already scheduled for deletion and grace period is over,
      // log the user out
      if (response.data.deletion_requested && response.data.days_remaining === 0) {
        await logout();
        navigate('/login');
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await logout();
        navigate('/login');
      } else {
        addMessage('Failed to fetch account status', 'error');
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchDeletionStatus();
    }
  }, [token]);

  const handleDeleteRequest = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('/api/auth/delete-account/confirm/', 
        { password },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      addMessage(response.data.detail, 'success');
      fetchDeletionStatus();
    } catch (error) {
      if (error.response?.status === 401) {
        await logout();
        navigate('/login');
      } else {
        const errorMessage = error.response?.data?.detail || 
          'An error occurred while processing your request';
        addMessage(errorMessage, 'error');
      }
    } finally {
      setLoading(false);
      setPassword('');
    }
  };

  const handleCancelDeletion = async () => {
    try {
      const response = await axios.post(
        '/api/auth/delete-account/cancel/',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      addMessage(response.data.detail, 'success');
      fetchDeletionStatus();
    } catch (error) {
      if (error.response?.status === 401) {
        await logout();
        navigate('/login');
      } else {
        addMessage('Failed to cancel account deletion', 'error');
      }
    }
  };

  // Add confirmation dialog
  const confirmDeletion = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone after 30 days.')) {
      handleDeleteRequest(e);
    }
  };

  if (!token) {
    return navigate('/login');
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold mb-6 text-red-600">Delete Account</h1>

        {deletionStatus?.deletion_requested ? (
          <div className="mb-6 bg-yellow-50 border border-yellow-200 rounded-md p-4">
            <h2 className="text-lg font-medium text-yellow-800 mb-2">
              Account Deletion in Progress
            </h2>
            <p className="text-yellow-700">
              Your account is scheduled for deletion. It will be permanently deleted in{' '}
              {deletionStatus.days_remaining} days.
            </p>
            <p className="text-yellow-700 mt-2">
              You will still be able to log in during this period. After {deletionStatus.days_remaining} days,
              your account will be permanently deleted.
            </p>
            <button
              onClick={handleCancelDeletion}
              className="mt-4 bg-yellow-600 text-white px-4 py-2 rounded-md hover:bg-yellow-700"
            >
              Cancel Deletion
            </button>
          </div>
        ) : (
          <form onSubmit={confirmDeletion} className="space-y-4">
            <div className="bg-yellow-50 border border-yellow-200 rounded-md p-4 mb-4">
              <p className="text-yellow-700">
                Please note: This will schedule your account for deletion in 30 days.
                During this period, you can still log in and cancel the deletion process.
              </p>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Please enter your password to confirm deletion:
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="Enter your password"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full py-2 px-4 rounded-md text-white ${
                loading ? 'bg-gray-400' : 'bg-red-600 hover:bg-red-700'
              } focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2`}
            >
              {loading ? 'Processing...' : 'Schedule Account Deletion'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default AccountDeletion;