import { useState, useEffect } from 'react';
import { Sidebar } from '../components/Sidebar';
import { TopBar } from '../components/TopBar';
import { Menu, X } from 'lucide-react';

const AdminLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isSidebarOpen && e.target.closest('.sidebar') === null && e.target.closest('.menu-button') === null) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isSidebarOpen]);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* TopBar */}
      <div className="sticky top-0 z-10">
        <TopBar>
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="lg:hidden menu-button p-2 rounded-md hover:bg-gray-200 transition-colors"
            aria-label="Toggle menu"
          >
            {isSidebarOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </TopBar>
      </div>

      <div className="flex h-[calc(100vh-4rem)] relative">
        {/* Desktop Sidebar */}
        <div className="hidden lg:block h-full">
          <Sidebar />
        </div>

        {/* Mobile Sidebar */}
        <div
          className={`
            lg:hidden fixed inset-0 z-50 transition-all duration-300 ease-in-out
            ${isSidebarOpen ? 'opacity-100 visible' : 'opacity-0 invisible pointer-events-none'}
          `}
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-50" />
          
          {/* Sliding sidebar */}
          <div
            className={`
              sidebar absolute left-0 top-0 h-full w-64 bg-white shadow-xl
              transform transition-transform duration-300 ease-in-out
              ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
            `}
          >
            <Sidebar />
          </div>
        </div>

        {/* Main content */}
        <main className="flex-1 min-w-0 p-4 lg:p-6 overflow-auto">
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;