import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../auth/authcontext';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useMessage } from '../../services/MessageService';
import {
  GenderChoices,
  HHIChoices,
  EducationChoices,
  MaritalStatusChoices,
  EthnicityChoices,
  RaceChoices,
  HispanicOriginChoices,
  EmploymentStatusChoices,
  PersonalIndustryChoices,
  AgeGenderChildrenChoices,
  ParentalStatusChoices,
} from '../../dropdowns/dropdownChoices';

const Profile = () => {
  const { token, user } = useContext(AuthContext);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addMessage } = useMessage();
  const [isOpen, setIsOpen] = useState(false); // Add this line
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.children-dropdown')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('/api/users/profile/', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProfileData(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // If it's a checkbox from the age_gender_children field, it's handled separately
    if (name === 'age_gender_children') {
      return; // This is handled in the checkbox onChange
    }
    
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        '/api/users/profile/',
        profileData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setProfileData(response.data);
      addMessage("Profile updated successfully", "success"); // Success message
    } catch (error) {
      addMessage("Failed to update profile. Please try again.", "error"); // Error message
    }
  };

  if (loading) {
    return <p>Loading profile...</p>;
  }

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg">
      {/* Display user information */}
      <div className="flex flex-col sm:flex-row items-center justify-between mb-6 gap-4">
        <div className="flex items-center">
          <div className="mr-4">
            <div className="w-20 h-20 rounded-full overflow-hidden shadow">
              <img src={profileData.avatar_url} alt="Avatar" />
            </div>
          </div>
          <div>
            <p className="text-xl font-semibold">{user?.getDisplayName}</p>
            <p className="text-gray-500">{user?.email}</p>
          </div>
        </div>

      </div>

      {/* Editable UserProfile form */}
      {profileData && (
        <form onSubmit={handleSubmit} className="space-y-5 text-left">
          {/* Form Field with Label */}
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Date of Birth
            </label>
            <input
              type="date"
              name="date_of_birth"
              value={profileData.date_of_birth || ''}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            />
          </div>

          {/* Gender */}
          <div>
            <label className="block text-sm font-medium text-gray-600">Gender</label>
            <select
              name="gender"
              value={profileData.gender || ''}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
              <option value="">Select Gender</option>
              {Object.entries(GenderChoices).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          {/* Ethnicity */}
          <div>
            <label className="block text-sm font-medium text-gray-600">Ethnicity</label>
            <select
              name="ethnicity"
              value={profileData.ethnicity || ''}
              onChange={handleInputChange}
              className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
              <option value="">Select Ethnicity</option>
              {Object.entries(EthnicityChoices).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

            {/* Race */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Race</label>
            <select
                name="race"
                value={profileData.race || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
                <option value="">Select Race</option>
                {Object.entries(RaceChoices).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
                ))}
            </select>
            </div>

            {/* Hispanic Origin */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Hispanic Origin</label>
            <select
                name="hispanic_origin"
                value={profileData.hispanic_origin || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
                <option value="">Select Hispanic Origin</option>
                {Object.entries(HispanicOriginChoices).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
                ))}
            </select>
            </div>

            {/* Household Income (HHI) */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Household Income</label>
            <select
                name="hhi"
                value={profileData.hhi || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
                <option value="">Select HHI</option>
                {Object.entries(HHIChoices).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
                ))}
            </select>
            </div>

            {/* Employment */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Employment</label>
            <select
                name="employment"  // Changed from "hhi" to "employment"
                value={profileData.employment || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
                <option value="">Select Employment Status</option>
                {Object.entries(EmploymentStatusChoices).map(([value, label]) => (
                    <option key={value} value={value}>{label}</option>
                ))}
            </select>
            </div>
            {/* Personal Industry */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Personal Industry</label>
            <select
                name="personal_industry"
                value={profileData.personal_industry || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
                <option value="">Select Industry</option>
                {Object.entries(PersonalIndustryChoices).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
                ))}
            </select>
            </div>

            {/* Marital Status */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Marital Status</label>
            <select
                name="marital_status"
                value={profileData.marital_status || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
                <option value="">Select Marital Status</option>
                {Object.entries(MaritalStatusChoices).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
                ))}
            </select>
            </div>

            {/* Parental Status */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Parental Status</label>
            <select
                name="parent_status"
                value={profileData.parent_status || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
                <option value="">Select Parental Status</option>
                {Object.entries(ParentalStatusChoices).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
                ))}
            </select>
            </div>

            {/* Age Gender Children */}
            <div>
              <label className="block text-sm font-medium text-gray-600">Age and Gender of Children</label>
              <div className="mt-1">
                  <div className="relative w-full children-dropdown">
                      <button
                          type="button"
                          onClick={() => setIsOpen(!isOpen)}
                          className="w-full text-left rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                      >
                          {Array.isArray(profileData.age_gender_children) && profileData.age_gender_children.length > 0
                              ? Object.entries(AgeGenderChildrenChoices)
                                  .filter(([value]) => profileData.age_gender_children.includes(value))
                                  .map(([_, label]) => label)
                                  .join(", ")
                              : "Select age and gender of children"}
                      </button>
                      
                      {isOpen && (
                          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
                              <div className="max-h-[200px] overflow-y-auto">
                                  {Object.entries(AgeGenderChildrenChoices).map(([value, label]) => (
                                      <label
                                          key={value}
                                          className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                                      >
                                          <input
                                              type="checkbox"
                                              checked={Array.isArray(profileData.age_gender_children) && 
                                                  profileData.age_gender_children.includes(value)}
                                              onChange={(e) => {
                                                  setProfileData(prevData => ({
                                                      ...prevData,
                                                      age_gender_children: e.target.checked
                                                          ? [...(Array.isArray(prevData.age_gender_children) 
                                                              ? prevData.age_gender_children 
                                                              : []), 
                                                            value]
                                                          : (Array.isArray(prevData.age_gender_children)
                                                              ? prevData.age_gender_children.filter(v => v !== value)
                                                              : [])
                                                  }));
                                              }}
                                              className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 mr-3"
                                          />
                                          <span className="text-gray-700">{label}</span>
                                      </label>
                                  ))}
                              </div>
                          </div>
                      )}
                  </div>
              </div>
          </div>

            {/* Education */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Education</label>
            <select
                name="education"
                value={profileData.education || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            >
                <option value="">Select Education</option>
                {Object.entries(EducationChoices).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
                ))}
            </select>
            </div>

            {/* Zipcode */}
            <div>
            <label className="block text-sm font-medium text-gray-600">Zipcode</label>
            <input
                type="text"
                name="zipcode"
                value={profileData.zipcode || ''}
                onChange={handleInputChange}
                className="mt-1 w-full rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            />
            </div>




          {/* Save Button */}
          <div className="text-center mt-6">
            <button
              type="submit"
              className="btn-profile-edit"
            >
              Save
            </button>
          </div>
        </form>
      )}
      
            {/* Section for Change Password, Subscribe, and Delete Account */}
            <div className="mt-12 pt-6 border-t border-gray-200">
        <div className="flex flex-col items-center space-y-4 text-sm text-gray-500">

          {/* Subscribe Link - only visible when subscribed is false */}
          {!profileData.subscribed && (
            <>
              <Link 
                to="/dashboard/subscribe" 
                className="text-blue-500 hover:text-blue-600 font-semibold"
              >
                Subscribe
              </Link>
              <p className="text-xs text-gray-400">
                Resubscribe to receive updates and communications.
              </p>
            </>
          )}

          {/* Change Password Link */}
          <Link 
            to="/dashboard/change-password" 
            className="text-blue-500 hover:text-blue-600 font-semibold"
          >
            Change Password
          </Link>

          {/* Delete Account Link */}
          <Link 
            to="/dashboard/delete-account" 
            className="text-gray-400 hover:text-red-600 transition-colors duration-200"
          >
            Delete account
          </Link>
          <p className="text-xs text-gray-400">
            Permanently remove your account and all associated data
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
