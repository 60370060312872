// src/main.jsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { store } from './app/store';
import { AppProviders } from './providers/AppProviders';
import { routes } from './routes';
import './index.css';

// Create router
const router = createBrowserRouter(routes);

// Root component
const Root = () => {
  return (
    <AppProviders store={store}>
      <RouterProvider router={router} />
    </AppProviders>
  );
};

// Render app
ReactDOM.createRoot(document.getElementById('root')).render(<Root />);