import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../auth/authcontext';
import { useMessage } from '../../services/MessageService';
import axios from 'axios';
import RedemptionHistory from "./RedemptionHistory";
import LevelUpProgress from "../../components/LevelUpProgress";

const Redeem = () => {
  const { token } = useContext(AuthContext);
  const { addMessage } = useMessage();
  const [userMetrics, setUserMetrics] = useState({});
  const [surveyStats, setSurveyStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [paypalEmail, setPaypalEmail] = useState(""); // New state for PayPal email
  const [isSavingPaypalEmail, setIsSavingPaypalEmail] = useState(false);

  const savePaypalEmail = async () => {
    setIsSavingPaypalEmail(true);
    try {
      await axios.post(
        '/api/redemptions/save-paypal-email/',
        { paypal_email: paypalEmail },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      addMessage("PayPal email saved successfully!", "success");
      fetchRedemptionData();
    } catch (error) {
      addMessage("Failed to save PayPal email. Please try again.", "error");
    } finally {
      setIsSavingPaypalEmail(false);
    }
  };

  // Not in use yet, waiting for the PayPal pending approval
  const connectPayPal = async (redeemType) => {
    try {
      if (redeemType) {
        sessionStorage.setItem('redeemType', redeemType);
        sessionStorage.setItem('authToken', token);
      }
      
      const response = await axios.get('/api/redemptions/connect-paypal/', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.data.oauth_url) {
        window.location.href = response.data.oauth_url;
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Failed to connect to PayPal. Please try again.";
      addMessage(errorMessage, "error");
    }
  };

  // Fetching the main redemption data
  const fetchRedemptionData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/redemptions/redemption/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserMetrics(response.data || {});
      setSurveyStats(response.data.survey_stats || {});

      const justConnected = sessionStorage.getItem('redeemType');
      if (justConnected && response.data.paypal_email) {
        addMessage("PayPal successfully connected! Click 'Redeem Now' to receive your rewards.", "success");
        sessionStorage.removeItem('redeemType');
      }
    } catch (error) {
      addMessage("Error loading redemption data. Please refresh the page.", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRedemptionData();
  }, [token]);

  const handleRedeem = async (redeemType) => {
    setIsRedeeming(true);
    const redeemEndpoint = redeemType === 'monthly_bonus_redeem'
      ? '/api/redemptions/redeem-monthly-rewards/'
      : '/api/redemptions/redeem-survey-rewards/';

    try {
      const response = await axios.post(
        redeemEndpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      addMessage(response.data.message || "Rewards redeemed successfully!", "success");
      fetchRedemptionData();
    } catch (error) {
      if (error.response?.status === 429) {
        addMessage("Please wait a few minutes before trying to redeem again.", "warning");
      } else {
        const errorMessage = error.response?.data?.error || "Failed to redeem rewards. Please try again.";
        addMessage(errorMessage, "error");
      }
    } finally {
      setIsRedeeming(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading metrics...</p>
        </div>
      </div>
    );
  }

  return (
    <section className="section max-w-6xl mx-auto p-4 space-y-8">
      {/* Progress Bar Section */}
      <LevelUpProgress
        userLevel={userMetrics.user_level}
        surveysNeeded={userMetrics.surveys_needed}
        nextLevel={userMetrics.next_level}
        progressPercentage={userMetrics.progress_percentage}
        className="progress-bar-card"
        headingTag="h3"
      />


      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Level & Rewards Stats */}
        <div className="bg-white shadow rounded-lg p-6 space-y-6">
          <h4 className="text-lg font-semibold text-gray-900">Level & Rewards</h4>
          <div className="space-y-4">
            <StatItem 
              label="User Level" 
              value={userMetrics.user_level || 1}
              description="There are 7 levels. Advance to the next level after completing 20 surveys."
            />
            <StatItem 
              label="Incentive Level" 
              value={`${userMetrics.incentive_level || 30}%`}
              description="Incentives range from 30% to 60% and are based on user level."
            />
            <StatItem 
              label="Lifetime Rewards" 
              value={`$${(userMetrics.total_rewards || 0).toFixed(2)}`}
              description="Total rewards for survey completes excluding reversals for the lifetime of this account."
            />
            <StatItem 
              label="Lifetime Bonuses" 
              value={`$${(userMetrics.total_monthly_bonus_rewards || 0).toFixed(2)}`}
              description="This includes your Paid Monthly Bonuses + Redeemable Monthly Bonus."
            />
            <StatItem 
              label="Paid Monthly Bonuses" 
              value={`$${(userMetrics.redeemed_monthly_bonuses || 0).toFixed(2)}`}
              description="This is how much you have already redeemed for monthly bonuses."
            />
          </div>
        </div>

        {/* Performance Stats */}
        <div className="bg-white shadow rounded-lg p-6 space-y-6">
          <h4 className="text-lg font-semibold text-gray-900">Performance</h4>
          <div className="space-y-4">
            <StatItem 
              label="Completes" 
              value={surveyStats.surveys_completed || 0}
              description="Total surveys completed for the lifetime of this account."
            />
            <StatItem 
              label="Survey Reversals" 
              value={surveyStats.total_reversals || 0}
              description="Number of surveys completed changed to incomplete for bad data."
            />
            <StatItem 
              label="Fraud Score" 
              value={userMetrics.fraud_score || '0'}
              description="Our system tracks whether you are trying to game the system. Be honest. Do not cheat."
            />
            <StatItem 
              label="Ban Status" 
              value={userMetrics.current_ban_status || 'N/A'}
              description="You will be banned for cheating and bad data."
            />
          </div>
        </div>

        {/* Rankings */}
        <div className="bg-white shadow rounded-lg p-6 space-y-6">
          <h4 className="text-lg font-semibold text-gray-900">Rankings</h4>
          <div className="space-y-4">
            <StatItem 
              label="Current Month Rank" 
              value={userMetrics.current_month_rank || 'N/A'}
              description="This is your rank for this month for total surveys completed compared to other users."
            />
            <StatItem 
              label="Previous Month Rank" 
              value={userMetrics.previous_month_rank || 'N/A'}
              description="This was your rank from last month for total surveys completed compared to other users."
            />
          </div>
        </div>

        {/* Redeemable Rewards */}
<div className="bg-white shadow rounded-lg p-6 space-y-6">
  <h4 className="text-lg font-semibold text-gray-900">Available Rewards</h4>
  <div className="space-y-6">
    {/* Monthly Bonus */}
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <span className="font-medium">Monthly Bonus</span>
        <span className="text-lg font-bold text-green-600">
          ${(userMetrics.redeemable_monthly_bonuses || 0).toFixed(2)}
        </span>
      </div>
      <p className="text-sm text-gray-600 max-w-[60%] text-left">
        Total monthly bonuses available for you to redeem today.
      </p>
      {userMetrics.redeemable_monthly_bonuses > 0.01 && (
        <>
          {userMetrics.paypal_email ? (
            <div className="space-y-4">
              <button
                className={`w-full btn-primary text-white rounded-lg py-2 px-4 
                  ${isRedeeming ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                onClick={() => handleRedeem('monthly_bonus_redeem')}
                disabled={isRedeeming}
              >
                {isRedeeming ? 'Redeeming...' : 'Redeem Monthly Bonus'}
              </button>
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-center">
                <svg className="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="ml-3 text-sm text-blue-700">
                  PayPal connected: {userMetrics.paypal_email}
                </p>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <input
                type="email"
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Enter your PayPal email"
                value={paypalEmail}
                onChange={(e) => setPaypalEmail(e.target.value)}
              />
              <button
                className={`btn-primary w-full py-2 ${isSavingPaypalEmail ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={savePaypalEmail}
                disabled={isSavingPaypalEmail || !paypalEmail}
              >
                {isSavingPaypalEmail ? 'Saving...' : 'Save PayPal Email'}
              </button>
            </div>
          )}
        </>
      )}
    </div>

    {/* Survey Rewards */}
    <div className="space-y-4 pt-4 border-t">
      <div className="flex justify-between items-center">
        <span className="font-medium">Survey Rewards</span>
        <span className="text-lg font-bold text-green-600">
          ${(userMetrics.avail_survey_rewards || 0).toFixed(2)}
        </span>
      </div>
      <p className="text-sm text-gray-600 max-w-[60%] text-left">
        Total survey rewards available for you to redeem today.{' '}
        <span className="text-sm text-gray-400">
          (Rewards for a given survey are available for redemption 20 days after you complete)
        </span>
      </p>
      {userMetrics.avail_survey_rewards > 0.01 && (
        <>
          {userMetrics.paypal_email ? (
            <div className="space-y-4">
              <button
                className={`w-full btn-primary text-white rounded-lg py-2 px-4 
                  ${isRedeeming ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                onClick={() => handleRedeem('survey_redeem')}
                disabled={isRedeeming}
              >
                {isRedeeming ? 'Redeeming...' : 'Redeem Survey Rewards'}
              </button>
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-center">
                <svg className="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="ml-3 text-sm text-blue-700">
                  PayPal connected: {userMetrics.paypal_email}
                </p>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <input
                type="email"
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Enter your PayPal email"
                value={paypalEmail}
                onChange={(e) => setPaypalEmail(e.target.value)}
              />
              <button
                className={`btn-primary w-full py-2 ${isSavingPaypalEmail ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={savePaypalEmail}
                disabled={isSavingPaypalEmail || !paypalEmail}
              >
                {isSavingPaypalEmail ? 'Saving...' : 'Save PayPal Email'}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  </div>
</div>

      </div>
      <RedemptionHistory />
    </section>
  );
};

const StatItem = ({ label, value, description }) => (
  <div className="space-y-1">
    <div className="flex justify-between items-center">
      <span className="font-medium text-gray-700">{label}</span>
      <span className="font-semibold text-gray-900">{value}</span>
    </div>
    <p className="text-sm text-gray-600 text-left max-w-[60%]">{description}</p>
  </div>
);

export default Redeem;