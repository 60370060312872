import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const SurveyOutcomesTab = ({ data }) => {
  if (!data) return null;

  // Helper function to format percentage
  const formatPercent = (value) => `${(value * 100).toFixed(1)}%`;

  // Define a color palette
  const colorPalette = [
    '#2563eb', '#34d399', '#f59e0b', '#ef4444', '#8b5cf6',
    '#10b981', '#f97316', '#e11d48', '#6366f1', '#6b7280',
    '#14b8a6', '#eab308', '#dc2626', '#9333ea', '#f87171',
  ];

  // Assign colors dynamically
  const getColor = (index) => colorPalette[index % colorPalette.length];

  return (
    <div className="space-y-6">
      {/* Time Series Chart */}
      <Card>
        <CardHeader>
          <CardTitle>Daily Survey Completion Rates</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={data?.daily_completion_rates}
                margin={{ top: 20, right: 30, left: 60, bottom: 40 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => new Date(date).toLocaleDateString()}
                  label={{ value: 'Date', position: 'bottom', offset: 0 }}
                />
                <YAxis 
                  tickFormatter={formatPercent}
                  label={{ value: 'Completion Rate', angle: -90, position: 'left', offset: 10 }}
                />
                <Tooltip 
                  labelFormatter={(date) => new Date(date).toLocaleDateString()}
                  formatter={(value) => [formatPercent(value), 'Completion Rate']}
                />
                <Line 
                  type="monotone" 
                  dataKey="completion_rate" 
                  stroke="#2563eb" 
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Marketplace Status Distribution */}
        <Card>
          <CardHeader>
            <CardTitle>Marketplace Status Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={data?.marketplace_status_dist}
                  layout="vertical"
                  margin={{ top: 20, right: 30, left: 150, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={formatPercent} />
                  <YAxis 
                    type="category" 
                    dataKey="status_name" 
                    width={140}
                  />
                  <Tooltip formatter={formatPercent} />
                  <Bar dataKey="percentage">
                    {data?.marketplace_status_dist.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(index)} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Client Status Distribution */}
        <Card>
          <CardHeader>
            <CardTitle>Client Status Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={data?.client_status_dist}
                  layout="vertical"
                  margin={{ top: 20, right: 30, left: 150, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={formatPercent} />
                  <YAxis 
                    type="category" 
                    dataKey="status_name" 
                    width={140}
                  />
                  <Tooltip formatter={formatPercent} />
                  <Bar dataKey="percentage">
                    {data?.client_status_dist.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(index)} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Key Metrics */}
        <Card>
          <CardHeader>
            <CardTitle>Survey Completion Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div>
                <div className="text-sm font-medium text-gray-500">Overall Completion Rate</div>
                <div className="mt-1 text-2xl font-semibold">
                  {formatPercent(data?.metrics?.overall_completion_rate || 0)}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Average Revenue per Survey</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${(data?.metrics?.avg_revenue_per_survey || 0).toFixed(2)}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Total Completed Surveys</div>
                <div className="mt-1 text-2xl font-semibold">
                  {(data?.metrics?.total_completed_surveys || 0).toLocaleString()}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Quality Metrics */}
        <Card>
          <CardHeader>
            <CardTitle>Quality Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div>
                <div className="text-sm font-medium text-gray-500">Quality Termination Rate</div>
                <div className="mt-1 text-2xl font-semibold">
                  {formatPercent(data?.metrics?.quality_term_rate || 0)}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Screener Pass Rate</div>
                <div className="mt-1 text-2xl font-semibold">
                  {formatPercent(data?.metrics?.screener_pass_rate || 0)}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Client Survey Pass Rate</div>
                <div className="mt-1 text-2xl font-semibold">
                  {formatPercent(data?.metrics?.client_survey_pass_rate || 0)}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SurveyOutcomesTab;