import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/authcontext';
import SignUpForm from '../components/SignupForm';
import CoupleTakingSurveys from '../assets/images/couple-taking-surveys-3.png';
import { FaTrophy, FaHandHoldingUsd, FaHandshake, FaCheck, FaUser, FaStar } from 'react-icons/fa';
import { GrLineChart } from "react-icons/gr";

function Home() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard/survey-entry');
    }
  }, [isAuthenticated, navigate]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className="relative">
      {/* Hero Section */}
      <div className="relative w-full md:h-[550px] lg:h-[600px] overflow-visible">
        {/* Background Image Container - Desktop */}
        <div 
          className="absolute inset-0 hidden md:block"
          style={{
            backgroundImage: `url(${CoupleTakingSurveys})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />

        {/* Mobile Background */}
        <div 
          className="relative md:hidden w-full h-[300px]"
          style={{
            backgroundImage: `url(${CoupleTakingSurveys})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />

        {/* Content Container */}
        <div className="relative h-full">
          {/* Desktop Content */}
          <div className="hidden md:block">
            {/* Text Content - Aligned to left edge */}
            <div className="absolute left-0 pt-12 pl-0">
              <div className="p-6 text-black bg-white bg-opacity-60 rounded-lg max-w-[430px]">
                <h3 className="text-4xl font-bold text-left">Elevate Your Voice and Earn More</h3>
                <p className="text-xl pt-6 text-left">You are worth more than you know</p>
                <p className="text-xl pt-6 text-left">Sign up to earn rewards for completing surveys</p>
              </div>
            </div>

            {/* Sign-Up Form - Right side */}
            <div className="absolute right-8 top-1/2 transform -translate-y-1/2 w-[375px]">
              <div className="bg-white p-8 rounded-xl shadow-2xl">
              <p className="text-xl pb-6 text-center">Starting Earning Extra Cash</p>
                <SignUpForm />
              </div>
            </div>
          </div>

          {/* Mobile Content - Stacked below background */}
          <div className="md:hidden px-4 mt-8">
            {/* Text Content */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-3xl font-bold text-left">Elevate Your Voice and Earn More</h3>
              <p className="text-lg mb-2 pt-2 text-left">You are worth more than you know</p>
              <p className="text-sm mb-4 text-left">Sign up to earn rewards for completing surveys</p>
              <SignUpForm />
            </div>
          </div>
        </div>
      </div>

      {/* How it Works Section - Full width */}
      <div className="relative w-full px-4 py-16 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div>
              <div className="bg-blue-50 rounded-full p-6 w-20 h-20 flex items-center justify-center mx-auto mb-6">
                <FaUser className="text-3xl text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-center">Create Profile</h3>
              <p className="text-gray-600 text-center">Complete your profile to unlock survey opportunities tailored to your demographics.</p>
            </div>
            <div>
              <div className="bg-blue-50 rounded-full p-6 w-20 h-20 flex items-center justify-center mx-auto mb-6">
                <FaCheck className="text-3xl text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-center">Complete Surveys</h3>
              <p className="text-gray-600 text-center">Participate in surveys from our trusted partners and earn rewards.</p>
            </div>
            <div>
              <div className="bg-blue-50 rounded-full p-6 w-20 h-20 flex items-center justify-center mx-auto mb-6">
                <FaStar className="text-3xl text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-center">Cash Out</h3>
              <p className="text-gray-600 text-center">Cash out your earnings via PayPal.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Four-column Features Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <h2 className="text-3xl font-bold text-center mb-12">Generous Rewards</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="text-center p-6 border rounded-lg shadow-lg bg-white">
            <h3 className="text-xl font-semibold mb-2">Level Up</h3>
            <p className="text-gray-600 mb-4">Earn higher incentives as you complete more surveys.</p>
            <GrLineChart className="text-4xl mx-auto" /> 
          </div>
          <div className="text-center p-6 border rounded-lg shadow-lg bg-white">
            <h3 className="text-xl font-semibold mb-2">Monthly Bonuses</h3>
            <p className="text-gray-600 mb-4">Top 3 users each month earn a bonus of $200, $300, and $500.</p>
            <FaTrophy className="text-4xl mx-auto" /> 
          </div>
          <div className="text-center p-6 border rounded-lg shadow-lg bg-white">
            <h3 className="text-xl font-semibold mb-2">Fair Incentives</h3>
            <p className="text-gray-600 mb-4">Once you reach Level 7, you will earn 60% of partner incentives.</p>
            <FaHandHoldingUsd className="text-4xl mx-auto" /> 
          </div>
          <div className="text-center p-6 border rounded-lg shadow-lg bg-white">
            <h3 className="text-xl font-semibold mb-2">Reliable Opportunities</h3>
            <p className="text-gray-600 mb-4">We have partnered with the best. More chances to complete.</p>
            <FaHandshake className="text-4xl mx-auto" /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;