// frontend/src/auth/authprovider.js
import React, { useState, useEffect, useMemo } from 'react';
import { AuthContext } from "./authcontext";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [csrfToken, setCsrfToken] = useState(null);
  
  // Add a buffer time (5 minutes) to refresh before expiration
  const EXPIRATION_BUFFER = 5 * 60; // 5 minutes in seconds

  const isTokenExpired = (token, buffer = 0) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp < (currentTime + buffer);
    } catch (error) {
      return true;
    }
  };

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken && !isTokenExpired(refreshToken)) {
      try {
        const response = await axios.post('/api/auth/token/refresh/', {
          refresh: refreshToken
        });
        const newToken = response.data.access;
        localStorage.setItem('accessToken', newToken);
        setToken(newToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        return newToken;
      } catch (error) {
        handleLogout(true);
        throw error;
      }
    }
    throw new Error('No valid refresh token');
  };

  const handleLogout = (redirect = true) => {
    setToken(null);
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common['Authorization'];
    if (redirect) {
      window.location.href = '/login';
    }
  };

  // Setup axios interceptors
  useEffect(() => {
    // Request interceptor - Check token before request
    const requestInterceptor = axios.interceptors.request.use(async (config) => {
      const token = localStorage.getItem('accessToken');
      
      // Skip token check for refresh token requests
      if (config.url?.includes('/api/auth/token/refresh/')) {
        return config;
      }

      // If token exists but will expire soon, refresh it
      if (token && isTokenExpired(token, EXPIRATION_BUFFER)) {
        try {
          const newToken = await refreshAccessToken();
          config.headers.Authorization = `Bearer ${newToken}`;
        } catch (error) {
          // Let the request proceed - response interceptor will handle any 401s
        }
      }
      return config;
    });

    // Response interceptor - Handle 401s
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newToken = await refreshAccessToken();
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            handleLogout(true);
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  // Load CSRF token
  useEffect(() => {
    const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
    if (csrfToken) {
      const tokenValue = csrfToken.split('=')[1];
      setCsrfToken(tokenValue);
      axios.defaults.headers.common['X-CSRFToken'] = tokenValue;
    }
  }, []);

  // Initial auth state setup
  useEffect(() => {
    const initializeAuth = async () => {
      const storedToken = localStorage.getItem('accessToken');
      
      if (storedToken && !isTokenExpired(storedToken, EXPIRATION_BUFFER)) {
        try {
          const response = await axios.get('/api/auth/user/', {
            headers: { Authorization: `Bearer ${storedToken}` },
          });
          setToken(storedToken);
          setUser(response.data);
          setIsAuthenticated(true);
        } catch (error) {
          try {
            await refreshAccessToken();
            const userResponse = await axios.get('/api/auth/user/');
            setUser(userResponse.data);
            setIsAuthenticated(true);
          } catch (refreshError) {
            handleLogout(false);
          }
        }
      } else if (storedToken) {
        try {
          await refreshAccessToken();
          const userResponse = await axios.get('/api/auth/user/');
          setUser(userResponse.data);
          setIsAuthenticated(true);
        } catch (error) {
          handleLogout(false);
        }
      }
      setIsLoading(false);
    };

    initializeAuth();
  }, []);

  const contextValue = useMemo(() => ({
    token,
    user,
    isAuthenticated,
    isLoading,
    csrfToken,
    isSuperuser: user?.is_superuser || false,
    hasUsablePassword: user?.has_usable_password || false,
    isAnonymous: user?.is_anonymous || false,
    getToken: () => {
      const currentToken = localStorage.getItem('accessToken');
      return currentToken && !isTokenExpired(currentToken) ? currentToken : null;
    },
    setUserDetails: (jwtToken) => {
      localStorage.setItem('refreshToken', jwtToken.refresh);
      setToken(jwtToken.access);
      localStorage.setItem('accessToken', jwtToken.access);
      setUser(jwtToken.user);
      setIsAuthenticated(true);
      setIsLoading(false);
      axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken.access}`;
    },
    logout: () => handleLogout(true),
  }), [token, user, isAuthenticated, isLoading, csrfToken]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;