import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Bell } from 'lucide-react';
import { useNotifications } from '../components/contexts/NotificationContext';

const NotificationBell = () => {
  
  try {
    const { unreadCount, notifications: allNotifications, error } = useNotifications();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);


    // Get only the 5 most recent notifications for the dropdown
    const notifications = allNotifications?.slice(0, 5) || [];

    // Handle clicking outside to close dropdown
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      // Handle escape key
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('touchstart', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleEscape);
        document.removeEventListener('touchstart', handleClickOutside);
      };
    }, []);

    // If there's an error, return null
    if (error) {

      return null;
    }

    return (
      <div className="relative static sm:relative" ref={dropdownRef}>
        {/* Bell Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="relative p-2 rounded-full hover:bg-blue-100 transition duration-200"
          aria-label="Notifications"
          type="button"
        >
          <Bell className="h-6 w-6 text-gray-700 hover:text-blue-600" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              {unreadCount}
            </span>
          )}
        </button>

        {/* Mobile-friendly dropdown */}
        {isOpen && (
          <div 
            className="fixed inset-x-0 top-16 mx-4 sm:mx-0 sm:absolute sm:right-0 sm:left-auto sm:top-auto sm:mt-2 
                       sm:w-80 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-4 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium">Recent Notifications</h3>
                <Link
                  to="/dashboard/notifications"
                  className="text-sm text-blue-600 hover:text-blue-800"
                  onClick={() => setIsOpen(false)}
                >
                  View All
                </Link>
              </div>
            </div>

            <div className="max-h-[calc(100vh-200px)] sm:max-h-96 overflow-y-auto overscroll-contain">
              {notifications.length === 0 ? (
                <div className="p-4 text-center text-gray-500">
                  No notifications
                </div>
              ) : (
                <div className="divide-y divide-gray-200">
                  {notifications.map((notification) => (
                    <Link
                      key={notification.id}
                      to="/dashboard/notifications"
                      onClick={() => setIsOpen(false)}
                      className={`block p-4 hover:bg-gray-50 cursor-pointer ${
                        !notification.is_read ? 'bg-blue-50' : ''
                      }`}
                    >
                      <div className="flex flex-col gap-1">
                        <div className="font-medium">
                          {notification.notification.title}
                        </div>
                        <div className="text-sm text-gray-600 line-clamp-2">
                          {notification.notification.message}
                        </div>
                        <div className="text-xs text-gray-500">
                          {new Date(notification.notification.created_at).toLocaleString()}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  } catch (error) {

    return null;
  }
};

export default NotificationBell;