// frontend/src/contexts/NotificationContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../../auth/authcontext';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isAuthenticated, getToken } = useContext(AuthContext);

  const fetchUnreadCount = async () => {
    if (!isAuthenticated || !getToken()) {
      setUnreadCount(0);
      return;
    }

    try {
      const response = await axios.get('/api/notifications/user/unread-count/');
      setUnreadCount(response.data.unread_count || 0);

      if (response.data.unread_count > 0 && document.hidden) {
        if (Notification.permission === 'granted') {
          new Notification('New Notification', {
            body: 'You have new notifications on LevelRev',
            icon: '/levelrev.svg',
          });
        }
      }
    } catch (error) {
      if (!error.response || ![401, 403].includes(error.response.status)) {
        setError(error);
      }
    }
  };

  const fetchNotifications = async () => {
    if (!isAuthenticated || !getToken()) {
      setNotifications([]);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get('/api/notifications/user/');
      setNotifications(Array.isArray(response.data.results) ? response.data.results : []);
    } catch (error) {
      if (!error.response || ![401, 403].includes(error.response.status)) {
        setError(error);
      }
      setNotifications([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated === undefined) return;
    if (!isAuthenticated) {
      setNotifications([]);
      setUnreadCount(0);
      setError(null);
      return;
    }
  
    fetchUnreadCount();
    fetchNotifications();
  
      
    // Keep cleanup function for consistency
    return () => {};
  }, [isAuthenticated]);

  const value = {
    unreadCount,
    notifications,
    loading,
    error,
    fetchNotifications,
    markAsRead: async (id) => {
      if (!isAuthenticated || !getToken()) return;  // Add this check
      try {
        await axios.post(`/api/notifications/user/${id}/mark-as-read/`);
        await Promise.all([fetchNotifications(), fetchUnreadCount()]);
      } catch (err) {
        setError(err);
      }
    },
    markAllAsRead: async () => {
      if (!isAuthenticated || !getToken()) return;  // Add this check
      try {
        await axios.post('/api/notifications/user/mark-all-as-read/');
        await Promise.all([fetchNotifications(), fetchUnreadCount()]);
      } catch (err) {
        setError(err);
      }
    },
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    return {
      unreadCount: 0,
      notifications: [],
      loading: false,
      error: null,
      fetchNotifications: () => {},
      markAsRead: () => {},
      markAllAsRead: () => {},
    };
  }

  return context;
};