import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';

const EngagementTab = ({ data }) => {
  const {
    active_users_24h = 0,
    active_users_7d = 0,
    active_users_30d = 0,
    total_logins = 0,
    average_session_time = 0,
    current_login_streaks = [],
    longest_streaks = []
  } = data || {};

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Active Users</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div>
                <div className="text-sm font-medium text-gray-500">Last 24 Hours</div>
                <div className="mt-1 text-2xl font-semibold">
                  {active_users_24h}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Last 7 Days</div>
                <div className="mt-1 text-2xl font-semibold">
                  {active_users_7d}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Last 30 Days</div>
                <div className="mt-1 text-2xl font-semibold">
                  {active_users_30d}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Session Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div>
                <div className="text-sm font-medium text-gray-500">Total Logins</div>
                <div className="mt-1 text-2xl font-semibold">
                  {total_logins}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Average Session Time</div>
                <div className="mt-1 text-2xl font-semibold">
                  {average_session_time} minutes
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Current Login Streaks</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {current_login_streaks.length > 0 ? (
                current_login_streaks.map((streak, index) => (
                  <div key={index} className="flex justify-between">
                    <span>User {index + 1}</span>
                    <span>{streak} days</span>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500">No current streaks</div>
              )}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Longest Login Streaks</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {longest_streaks.length > 0 ? (
                longest_streaks.map((streak, index) => (
                  <div key={index} className="flex justify-between">
                    <span>User {index + 1}</span>
                    <span>{streak} days</span>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500">No streak data</div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EngagementTab;