import { useState, useEffect } from 'react';
import Dialog from './ui/Dialog';
import Button from './ui/Button';

const ModelForm = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  initialData = null,
  bulkEditData = [],
  fields 
}) => {
  const [formData, setFormData] = useState({});
  const [selectedFields, setSelectedFields] = useState(new Set());
  const isBulkEdit = bulkEditData.length > 0;

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      setSelectedFields(new Set(Object.keys(initialData)));
    } else if (!isBulkEdit) {
      // Reset form for new record
      const defaultData = {};
      fields.forEach(field => {
        defaultData[field.key] = '';
      });
      setFormData(defaultData);
      setSelectedFields(new Set(Object.keys(defaultData)));
    } else {
      // Reset form for bulk edit
      setFormData({});
      setSelectedFields(new Set());
    }
  }, [initialData, bulkEditData, fields]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isBulkEdit) {
      // Only include selected fields in bulk edit
      const selectedData = {};
      selectedFields.forEach(field => {
        if (formData[field] !== undefined) {
          selectedData[field] = formData[field];
        }
      });
      onSubmit(selectedData);
    } else {
      onSubmit(formData);
    }
  };

  const handleFieldSelect = (field) => {
    const newSelected = new Set(selectedFields);
    if (newSelected.has(field)) {
      newSelected.delete(field);
    } else {
      newSelected.add(field);
    }
    setSelectedFields(newSelected);
  };

  return (
    <Dialog 
      isOpen={isOpen} 
      onClose={onClose}
      title={isBulkEdit ? `Bulk Edit ${bulkEditData.length} Records` : (initialData ? 'Edit Record' : 'Create New Record')}
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        {fields.map((field) => (
          <div key={field.key} className="space-y-2">
            {isBulkEdit && (
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedFields.has(field.key)}
                  onChange={() => handleFieldSelect(field.key)}
                  id={`select-${field.key}`}
                />
                <label 
                  htmlFor={`select-${field.key}`}
                  className="text-sm font-medium text-gray-500"
                >
                  Include in bulk edit
                </label>
              </div>
            )}
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <input
              type={field.type || 'text'}
              value={formData[field.key] || ''}
              onChange={(e) => {
                const value = field.type === 'checkbox' ? e.target.checked : e.target.value;
                setFormData({ ...formData, [field.key]: value });
                if (isBulkEdit) {
                  setSelectedFields(new Set([...selectedFields, field.key]));
                }
              }}
              disabled={isBulkEdit && !selectedFields.has(field.key)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm disabled:bg-gray-100"
            />
            {isBulkEdit && selectedFields.has(field.key) && (
              <p className="text-sm text-gray-500">
                This will update this field for all selected records
              </p>
            )}
          </div>
        ))}
        <div className="flex justify-end gap-2 pt-4">
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            type="submit" 
            variant="primary"
            disabled={isBulkEdit && selectedFields.size === 0}
          >
            {isBulkEdit 
              ? `Update ${bulkEditData.length} Records` 
              : (initialData ? 'Update' : 'Create')}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default ModelForm;