// DashboardLayout.jsx
import ProtectedRoute from "./auth/protectedroute";
import AppNav from "./appnav";
import React, { useContext, useState } from "react";
import { AuthContext } from "../auth/authcontext";
import { useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import {
  FaHome,
  FaChartLine,
  FaWallet,
  FaCompass,
  FaPenSquare,
  FaUser,
  FaLock,
  FaBell,
} from "react-icons/fa";

const DashboardLayoutContent = ({ children }) => {
  const { isAuthenticated, isSuperuser } = useContext(AuthContext);
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const sidebarNavItems = [
    {
      title: "Survey Opportunities",
      href: "/dashboard/survey-entry",
      icon: <FaHome />,
      permission: isAuthenticated,
      active: location.pathname === "/dashboard/survey-entry",
    },
    {
      title: "Leaderboard",
      href: "/dashboard/leaderboard",
      icon: <FaChartLine />,
      permission: isAuthenticated,
      active: location.pathname === "/dashboard/leaderboard",
    },
    {
      title: "Redeem",
      href: "/dashboard/redeem-rewards",
      icon: <FaWallet />,
      permission: isAuthenticated,
      active: location.pathname.includes("/dashboard/redeem-rewards"),
    },
    {
      title: "Survey Strategy",
      href: "/dashboard/survey-strategy",
      icon: <FaCompass />,
      permission: isAuthenticated,
      active: location.pathname.includes("/dashboard/survey-strategy"),
    },
    {
      title: "Participation Rules",
      href: "/dashboard/survey-rules",
      icon: <FaPenSquare />,
      permission: isAuthenticated,
      active: location.pathname.includes("/dashboard/survey-rules"),
    },
    {
      title: "User Profile",
      href: "/dashboard/profile",
      icon: <FaUser />,
      permission: isAuthenticated,
      active: location.pathname.includes("/dashboard/profile"),
    },
    {
      title: "Notifications",
      href: "/dashboard/notifications",
      icon: <FaBell />,
      permission: isAuthenticated && isSuperuser,
      active: location.pathname.includes("/dashboard/notifications"),
    },
    {
      title: "Admin Panel",
      href: "/dashboard/admin",
      icon: <FaLock />,
      permission: isAuthenticated && isSuperuser,
      active: location.pathname.includes("/dashboard/admin"),
    },
  ];

  const filteredNavItems = sidebarNavItems.filter((item) => item.permission);

  // Determine current page title
  const currentPage = filteredNavItems.find((item) => item.active)?.title || "Dashboard";

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header for Mobile */}
      <div className="md:hidden flex justify-between items-center px-4 py-2 border-b border-gray-300">
        <h1 className="text-lg font-semibold text-gray-700">{currentPage}</h1>
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="text-gray-700 hover:text-gray-900 focus:outline-none"
        >
          {isSidebarOpen ? <FaTimes className="h-6 w-6" /> : <FaBars className="h-6 w-6" />}
        </button>
      </div>

      {/* Main Content */}
      <div className="flex flex-col md:flex-row flex-grow relative">
        {/* Sidebar */}
        <div
          className={`fixed inset-y-0 left-0 z-50 w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } md:static md:translate-x-0`}
        >
          <AppNav items={filteredNavItems} />
        </div>

        {/* Overlay for Mobile Sidebar */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}

        {/* Main Content Area */}
        <div className="flex-grow p-4 md:p-12">
          {/* Heading for Larger Screens */}
          <div className="hidden md:block mb-6">
            <h1 className="text-2xl font-semibold text-gray-700">{currentPage}</h1>
          </div>
          <ProtectedRoute>{children}</ProtectedRoute>
        </div>
      </div>
    </div>
  );
};


const DashboardLayout = ({ children }) => {
  return (

      <DashboardLayoutContent>{children}</DashboardLayoutContent>

  );
};

export default DashboardLayout;