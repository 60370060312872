// src/components/ErrorBoundary.jsx
import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // Instead of console.log, we'll show it in the UI
    this.setState({ error: error.message });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-2 text-xs text-red-600">
          {this.state.error}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;