// src/routes/dashboardRoutes.jsx
import { Navigate } from 'react-router-dom';
import {
  Dashboard,
  Profile,
  ChangePasswordPage,
  CintLinkEntry,
  Redeem,
  Leaderboard,
  SurveyStrategyPage,
  SurveyRulesPage,
  AccountDeletion,
  Subscribe,
  NotificationList,
  OnboardingFlow
} from '../pages';

const DisabledRoute = () => {
  return <Navigate to="/dashboard/survey-entry" replace />;
};

export const dashboardRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    children: [
      { path: 'onboarding', element: <OnboardingFlow /> },
      { path: 'profile', element: <Profile /> },
      { path: 'change-password', element: <ChangePasswordPage /> },
      { path: 'survey-opps', element: <DisabledRoute /> },
      { path: 'survey-entry', element: <CintLinkEntry /> },
      { path: 'redeem-rewards', element: <Redeem /> },
      { path: 'leaderboard', element: <Leaderboard /> },
      { path: 'survey-strategy', element: <SurveyStrategyPage /> },
      { path: 'survey-rules', element: <SurveyRulesPage /> },
      { path: 'delete-account', element: <AccountDeletion /> },
      { path: 'subscribe', element: <Subscribe /> },
      { path: 'notifications', element: <NotificationList /> },
    ],
  }
];