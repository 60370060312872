import React from 'react';

export const TopBar = ({ children }) => {
  return (
    <div className="bg-white shadow-sm h-16 flex items-center px-4 lg:px-8">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-4">
          {children}
          <h1 className="text-xl font-semibold text-gray-800">LevelRev Admin</h1>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-600 hidden sm:inline">Admin User</span>
        </div>
      </div>
    </div>
  );
};

export default TopBar;