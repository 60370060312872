// frontend/src/components/admin/components/NotificationManager.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from './DataTable';
import { Dialog } from './ui/Dialog';
import { toast } from 'react-hot-toast';

const NotificationManager = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isSelectingUsers, setIsSelectingUsers] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    message: '',
    notification_type: 'system',
    scheduled_for: null
  });
  const [userPagination, setUserPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0
  });

  const columns = [
    { key: 'title', label: 'Title' },
    { 
      key: 'notification_type', 
      label: 'Type',
      render: (value) => (
        <span className="capitalize">{value.replace('_', ' ')}</span>
      )
    },
    { 
      key: 'status', 
      label: 'Status',
      render: (value) => (
        <span className={`px-2 py-1 text-xs font-medium rounded-full ${
          value === 'sent'
            ? 'bg-green-100 text-green-800'
            : value === 'failed'
            ? 'bg-red-100 text-red-800'
            : value === 'scheduled'
            ? 'bg-yellow-100 text-yellow-800'
            : 'bg-gray-100 text-gray-800'
        }`}>
          {value.toUpperCase()}
        </span>
      )
    },
    { 
      key: 'created_at', 
      label: 'Created At',
      render: (value) => new Date(value).toLocaleString()
    },
    { 
      key: 'scheduled_for', 
      label: 'Scheduled For',
      render: (value) => value ? new Date(value).toLocaleString() : '-'
    },
    { key: 'recipients_count', label: 'Recipients' },
    { key: 'read_count', label: 'Read Count' },
    {
      key: 'read_rate',
      label: 'Read Rate',
      render: (_, row) => {
        const rate = row.recipients_count ? 
          ((row.read_count / row.recipients_count) * 100).toFixed(1) : 
          0;
        return (
          <span className={`px-2 py-1 text-xs font-medium rounded-full ${
            rate > 75
              ? 'bg-green-100 text-green-800'
              : rate > 50
              ? 'bg-blue-100 text-blue-800'
              : rate > 25
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-red-100 text-red-800'
          }`}>
            {rate}%
          </span>
        );
      }
    }
  ];

  const fetchNotifications = async (page = 1, search = '') => {
    try {
      setLoading(true);
      const response = await axios.get('/api/custom-admin/models/notifications/notification/', {
        params: { page, search }
      });
      
      setNotifications(response.data.results);
      setPagination({
        currentPage: response.data.current_page,
        totalPages: response.data.total_pages,
        total: response.data.count,
        startIndex: response.data.start_index - 1,
        endIndex: response.data.end_index,
        hasNext: response.data.has_next,
        hasPrevious: response.data.has_previous
      });
    } catch (error) {
      toast.error('Failed to fetch notifications');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async (page = 1) => {
    try {
      const response = await axios.get('/api/custom-admin/models/users/customuser/', {
        params: { page }
      });
      setUsers(response.data.results);
      setUserPagination({
        currentPage: response.data.current_page,
        totalPages: response.data.total_pages,
        total: response.data.count
      });
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  };
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/custom-admin/models/notifications/notification/', formData);
      toast.success('Notification created successfully');
      setIsCreating(false);
      setFormData({
        title: '',
        message: '',
        notification_type: 'system',
        scheduled_for: null
      });
      fetchNotifications();
    } catch (error) {
      toast.error('Failed to create notification');
    }
  };

  const handleSendToUsers = async (notification) => {
    if (!selectedUsers.length) {
      toast.error('Please select users to send the notification to');
      return;
    }

    try {
      await axios.post(
        `/api/custom-admin/models/notifications/notification/${notification.id}/send-to-users/`,
        { user_ids: selectedUsers }
      );
      toast.success('Notification sent successfully');
      setIsSelectingUsers(false);
      setSelectedUsers([]);
      setSelectedNotification(null);
      fetchNotifications();
    } catch (error) {
      toast.error('Failed to send notification');
    }
  };

  const handleDelete = async (notification) => {
    if (!window.confirm('Are you sure you want to delete this notification?')) {
      return;
    }

    try {
      await axios.delete(`/api/custom-admin/models/notifications/notification/${notification.id}/`);
      toast.success('Notification deleted successfully');
      fetchNotifications();
    } catch (error) {
      toast.error('Failed to delete notification');
    }
  };

  const handleBulkDelete = async (ids) => {
    if (!window.confirm(`Are you sure you want to delete ${ids.length} notifications?`)) {
      return;
    }

    try {
      await axios.post('/api/custom-admin/models/notifications/notification/bulk-delete/', { ids });
      toast.success('Notifications deleted successfully');
      fetchNotifications();
    } catch (error) {
      toast.error('Failed to delete notifications');
    }
  };

  const handleSendClick = (notification) => {
    setSelectedNotification(notification);
    setIsSelectingUsers(true);
    fetchUsers();
  };

  const handleUserPageChange = (newPage) => {
    fetchUsers(newPage);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const renderUserSelectionDialog = () => (
    <Dialog 
      isOpen={isSelectingUsers} 
      onClose={() => setIsSelectingUsers(false)}
    >
      <div className="p-6">
        <h2 className="text-lg font-semibold mb-4">
          Select Recipients 
          <span className="text-sm text-gray-500 ml-2">
            ({selectedUsers.length} selected)
          </span>
        </h2>
        
        <div className="mb-4 text-sm text-gray-600">
          Showing {users.length} users of {userPagination.total} total
        </div>

        <div className="max-h-96 overflow-y-auto border rounded-lg">
          {users.map(user => (
            <label 
              key={user.id} 
              className="flex items-center space-x-2 p-2 hover:bg-gray-50 border-b last:border-b-0"
            >
              <input
                type="checkbox"
                checked={selectedUsers.includes(user.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedUsers([...selectedUsers, user.id]);
                  } else {
                    setSelectedUsers(selectedUsers.filter(id => id !== user.id));
                  }
                }}
                className="rounded border-gray-300"
              />
              <span className="flex-grow">{user.email}</span>
              <span className="text-sm text-gray-500">{user.username}</span>
            </label>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="mt-4 flex items-center justify-between border-t pt-4">
          <div className="text-sm text-gray-600">
            Page {userPagination.currentPage} of {userPagination.totalPages}
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => handleUserPageChange(userPagination.currentPage - 1)}
              disabled={userPagination.currentPage === 1}
              className="px-3 py-1 border rounded disabled:opacity-50"
            >
              Previous
            </button>
            <button
              onClick={() => handleUserPageChange(userPagination.currentPage + 1)}
              disabled={userPagination.currentPage === userPagination.totalPages}
              className="px-3 py-1 border rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="mt-4 flex justify-end space-x-2 border-t pt-4">
          <button
            type="button"
            onClick={() => setIsSelectingUsers(false)}
            className="px-4 py-2 border rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => handleSendToUsers(selectedNotification)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Send to {selectedUsers.length} Users
          </button>
        </div>
      </div>
    </Dialog>
  );

  return (
    <div className="space-y-6">
      <DataTable
        data={notifications}
        columns={columns}
        onDelete={handleDelete}
        onBulkDelete={handleBulkDelete} 
        onAdd={() => setIsCreating(true)}
        isLoading={loading}
        pagination={pagination}
        onPageChange={fetchNotifications}
        customActions={(notification) => (
          <button
            onClick={() => handleSendClick(notification)}
            className="px-3 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Send
          </button>
        )}
      />

      <Dialog isOpen={isCreating} onClose={() => setIsCreating(false)}>
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Create Notification</h2>
          <form onSubmit={handleCreate} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Title</label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.title}
                onChange={e => setFormData({...formData, title: e.target.value})}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Message</label>
              <textarea
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                rows="4"
                value={formData.message}
                onChange={e => setFormData({...formData, message: e.target.value})}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Type</label>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.notification_type}
                onChange={e => setFormData({...formData, notification_type: e.target.value})}
                required
              >
                <option value="system">System</option>
                <option value="survey">Survey</option>
                <option value="reward">Reward</option>
                <option value="account">Account</option>
                <option value="promotion">Promotion</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Schedule For (Optional)</label>
              <input
                type="datetime-local"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.scheduled_for || ''}
                onChange={e => setFormData({...formData, scheduled_for: e.target.value})}
              />
            </div>

            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setIsCreating(false)}
                className="px-4 py-2 border rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </Dialog>

      {renderUserSelectionDialog()}
    </div>
  );
};

export default NotificationManager;