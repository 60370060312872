import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../auth/authcontext";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import CintSurveyHistory from "./CintSurveyHistory";
import LevelUpProgress from "../../components/LevelUpProgress";

const CintLinkEntry = () => {
  const { token } = useContext(AuthContext);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userLevel, setUserLevel] = useState(1);
  const [totalRewards, setTotalRewards] = useState("0.00");
  const [availSurveyRewards, setAvailSurveyRewards] = useState("0.00");
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [surveysNeeded, setSurveysNeeded] = useState(0);
  const [nextLevel, setNextLevel] = useState(2);
  const [currentMonthRank, setCurrentMonthRank] = useState(0);

  const fieldToQuestionId = {
    age: 42,
    gender: 43,
    ethnicity: 157550,
    race: 113,
    hispanic_origin: 47,
    hhi: 61076,
    employment: 2189,
    personal_industry: 5729,
    marital_status: 632,
    parent_status: 7064,
    age_gender_children: 1249,
    education: 48741,
    zipcode: 45,
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get("/api/users/profile/", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProfileData(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    const fetchStats = async () => {
      try {
        const response = await axios.get("/api/integrations/stats/", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;
        setUserLevel(data.user_level || 1);
        setTotalRewards(data.total_rewards || "0.00");
        setAvailSurveyRewards(data.avail_survey_rewards || "0.00");
        setProgressPercentage(data.progress_percentage || 0);
        setSurveysNeeded(data.surveys_needed || 0);
        setNextLevel(data.next_level || 2);
        setCurrentMonthRank(data.user_game_stats?.current_month_rank || 0);
      } catch (error) {
      }
    };

    fetchProfile();
    fetchStats();
  }, [token]);
  
  const handleBegin = async () => {
    if (!profileData || !profileData.uuid) {
      return;
    }
  
    const sessionId = uuidv4();
  
    const queryParams = new URLSearchParams();
    queryParams.append("pid", profileData.uuid);
    queryParams.append("clid", 9);
    queryParams.append("loi", 15);
    queryParams.append("rev", 0.75);
    queryParams.append("mid", sessionId);
  
    Object.keys(fieldToQuestionId).forEach((field) => {
      if (field !== 'age_gender_children' && profileData[field] && fieldToQuestionId[field]) {
        queryParams.append(fieldToQuestionId[field], profileData[field]);
      }
    });

    if (profileData.age_gender_children && Array.isArray(profileData.age_gender_children)) {
      profileData.age_gender_children.forEach(value => {
        queryParams.append(fieldToQuestionId.age_gender_children, value);
      });
    }
  
    const baseUrl = `https://www.samplicio.us/s/default.aspx?sid=3d1d031a-3b32-4748-a02a-5a51c683f5fb`;
    const urlWithoutHash = `${baseUrl}&${queryParams.toString()}&`;
  
    try {
      const hashResponse = await axios.post(
        "/api/cint/generate-hash/",
        { url: urlWithoutHash },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const hash = hashResponse.data.hash;
      const surveyLink = `${urlWithoutHash}hash=${hash}`;
      
      // Store the auth token in sessionStorage before navigating
      sessionStorage.setItem('authToken', token);
      
      // Create a session record before navigating
      await axios.post(
        "/api/cint/cint-survey-sessions/",
        {
          pid: profileData.uuid,
          mid: sessionId,
          question_id: profileData,
          url: surveyLink,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Navigate to the survey URL in the same window
      window.location.href = surveyLink;
    } catch (error) {

    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-4">
      <LevelUpProgress
        userLevel={userLevel}
        surveysNeeded={surveysNeeded}
        nextLevel={nextLevel}
        progressPercentage={progressPercentage}
        className="shadow-lg"
      />
      
      <section className="text-center">
        {loading ? (
          <p>Loading your data...</p>
        ) : (
          <button
            onClick={handleBegin}
            className="btn-primary text-white px-6 py-3 mt-8 rounded-lg font-semibold"
          >
            Click to Begin your Next Survey
          </button>
        )}
      </section>

      <section className="text-center">
        <p className="text-gray-600 m-10 px-4 sm:px-6 md:px-20">
          Remember that quality responses are important. Please answer honestly and thoughtfully. Take your time. There are plenty of survey opportunities available. Good luck!
        </p>
      </section>

      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatsCard
          title="User Level"
          value={userLevel || "N/A"}
          tooltip="There are 7 levels. Advance to the next level after completing required surveys."
        />
        <StatsCard
          title="Lifetime Rewards"
          value={`$${parseFloat(totalRewards).toFixed(2)}`}
          tooltip="Total rewards for survey completes excluding reversals for the lifetime of this account."
        />
        <StatsCard
          title="Available Rewards"
          value={`$${parseFloat(availSurveyRewards).toFixed(2)}`}
          tooltip="Rewards available for immediate redemption."
        />
        <StatsCard
          title="Current Month Rank"
          value={`#${currentMonthRank}`}
          tooltip="Your current ranking among all users for this month's survey completions."
        />
      </section>

      <CintSurveyHistory />
   </div>
  );
};

const StatsCard = ({ title, value, tooltip }) => (
  <div
    className="bg-white border rounded-lg shadow p-4 flex flex-col items-center text-center"
    title={tooltip}
  >
    <h5 className="text-lg font-semibold text-gray-700">{title}</h5>
    <p className="text-xl font-bold mt-1">{value}</p>
  </div>
);

export default CintLinkEntry;