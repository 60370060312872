// frontend/src/pages/PasswordResetConfirm.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessage } from '../services/MessageService';

function PasswordResetConfirm() {
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const { addMessage } = useMessage();
  const navigate = useNavigate();
  const { uid, token } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (newPassword1 !== newPassword2) {
      addMessage('Passwords do not match', 'error');
      return;
    }

    try {
      await axios.post('/api/auth/password/reset/confirm/', {
        password: newPassword1,  
        uidb64: uid,         
        token: token
      });
      
      addMessage('Password successfully reset!', 'success');
      navigate('/login');
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 'Failed to reset password. Please try again.';
      addMessage(errorMessage, 'error');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Set New Password</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="password"
          value={newPassword1}
          onChange={(e) => setNewPassword1(e.target.value)}
          placeholder="New Password"
          required
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          value={newPassword2}
          onChange={(e) => setNewPassword2(e.target.value)}
          placeholder="Confirm New Password"
          required
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          Reset Password
        </button>
      </form>
    </div>
  );
}

export default PasswordResetConfirm;