// frontend/src/components/admin/components/Sidebar.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const Sidebar = () => {
  const location = useLocation();

  const menuItems = [
    { name: 'Models', path: '/dashboard/admin' },
    { name: 'Task Manager', path: '/dashboard/admin/tasks' },
    { name: 'User Analytics', path: '/dashboard/admin/analytics' },
    { name: 'Notifications', path: '/dashboard/admin/notifications' },
  ];

  return (
    <div className="w-64 bg-white shadow-sm h-[calc(100vh-4rem)] p-4">
      <nav className="space-y-2">
        {menuItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`block px-4 py-2 rounded-lg text-sm ${
              location.pathname === item.path
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            {item.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;