import { useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from './ui/Table';
import Button from './ui/Button';
import { Search, ChevronLeft, ChevronRight } from 'lucide-react';

const DataTable = ({ 
  data = [], 
  columns = [], 
  onEdit, 
  onDelete,
  onBulkDelete,
  onAdd,
  isLoading,
  pagination,
  onPageChange,
  onBulkEdit,
  onSearch,
  customActions
}) => {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [expandedCells, setExpandedCells] = useState(new Set());

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(new Set(data.map(row => row.id)));
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleSelectRow = (id) => {
    const newSelected = new Set(selectedRows);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelectedRows(newSelected);
  };

  const handleExpandCell = (cellId) => {
    const newExpanded = new Set(expandedCells);
    if (newExpanded.has(cellId)) {
      newExpanded.delete(cellId);
    } else {
      newExpanded.add(cellId);
    }
    setExpandedCells(newExpanded);
  };

  const handleBulkDelete = () => {
    if (selectedRows.size > 0) {
      onBulkDelete(Array.from(selectedRows));
      setSelectedRows(new Set());
    }
  };

  const handleBulkEdit = () => {
    if (selectedRows.size > 0) {
      onBulkEdit(Array.from(selectedRows));
      setSelectedRows(new Set());
    }
  };

  const renderCellContent = (content, cellId) => {
    const isExpanded = expandedCells.has(cellId);
    const stringContent = String(content);
    const shouldTruncate = stringContent.length > 100;

    if (!shouldTruncate) {
      return <div className="whitespace-pre-wrap">{stringContent}</div>;
    }

    return (
      <div>
        <div className={`whitespace-pre-wrap ${isExpanded ? '' : 'line-clamp-2'}`}>
          {stringContent}
        </div>
        {shouldTruncate && (
          <button
            onClick={() => handleExpandCell(cellId)}
            className="text-blue-600 hover:text-blue-800 text-sm mt-1"
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow min-w-0">
      {/* Actions Bar */}
      <div className="p-4 border-b flex flex-wrap justify-between items-center gap-4">
        <div className="flex flex-wrap items-center gap-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search..."
              className="w-64 pl-10 pr-4 py-2 border rounded-lg"
              onChange={(e) => onSearch?.(e.target.value)}
            />
          </div>
          {selectedRows.size > 0 && (
            <div className="flex items-center gap-2">
              <Button onClick={handleBulkEdit} variant="secondary">
                Edit Selected ({selectedRows.size})
              </Button>
              <Button onClick={handleBulkDelete} variant="danger">
                Delete Selected ({selectedRows.size})
              </Button>
            </div>
          )}
        </div>
        <Button onClick={onAdd} variant="primary">
          Add New
        </Button>
      </div>

      {/* Table */}
      <div className="relative overflow-x-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="w-4">
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedRows.size === data.length}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  className="whitespace-nowrap font-medium text-gray-500 uppercase tracking-wider"
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell className="w-20">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length + 2} className="text-center py-4">
                  Loading...
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 2} className="text-center py-4">
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row.id} className="hover:bg-gray-50">
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={selectedRows.has(row.id)}
                      onChange={() => handleSelectRow(row.id)}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell 
                      key={column.key}
                      className="max-w-lg"
                    >
                      {column.render ? 
                        column.render(row[column.key], row) :
                        renderCellContent(row[column.key], `${row.id}-${column.key}`)}
                    </TableCell>
                  ))}
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => onEdit(row)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => onDelete(row)}
                      >
                        Delete
                      </Button>
                      {customActions && customActions(row)}
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>

      {/* Pagination */}
      {pagination && (
        <div className="flex items-center justify-between border-t p-4">
          <div className="text-sm text-gray-700">
            Showing {pagination.startIndex + 1} to {Math.min(pagination.endIndex, pagination.total)} of {pagination.total} results
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => onPageChange?.(pagination.currentPage - 1)}
              disabled={!pagination.hasPrevious}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                !pagination.hasPrevious ? 'cursor-not-allowed' : ''
              }`}
            >
              Previous
            </button>
            {[...Array(pagination.totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => onPageChange?.(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  pagination.currentPage === index + 1
                    ? 'bg-teal-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600'
                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => onPageChange?.(pagination.currentPage + 1)}
              disabled={!pagination.hasNext}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                !pagination.hasNext ? 'cursor-not-allowed' : ''
              }`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataTable;