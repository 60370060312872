import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../auth/authcontext";
import axios from 'axios';

const CintSurveyHistory = () => {
  const { token } = useContext(AuthContext);
  const [surveyHistory, setSurveyHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchSurveyHistory = async () => {
      try {
        const response = await axios.get(`/api/cint/cint-survey-history/?page=${currentPage}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // No more filtering needed as backend handles it
        setSurveyHistory(response.data.results || []);
        setTotalPages(Math.ceil(response.data.count / 10));
        setTotalRecords(response.data.count);
      } catch (error) {
        setError("Failed to load survey history");
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyHistory();
  }, [token, currentPage]);

  const getStatusColor = (status) => {
    return status === 'Complete' ? 'text-green-600' : 'text-red-600';
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  if (loading) {
    return <div className="text-center py-4">Loading survey history...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-600">{error}</div>;
  }

  if (!surveyHistory.length) {
    return (
      <section className="bg-white rounded-lg shadow-lg p-6 mt-8">
        <h2 className="text-2xl font-semibold mb-4">Survey History</h2>
        <p className="text-center text-gray-500">No survey history available.</p>
      </section>
    );
  }

  // Update this section in CintSurveyHistory.jsx
return (
  <section className="bg-white rounded-lg shadow-lg p-2 sm:p-6 mt-8">
    <h2 className="text-2xl font-semibold mb-4">Survey History</h2>
    <div className="overflow-x-auto -mx-2 sm:mx-0">
      <table className="w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-3 sm:px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-3 sm:px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              Incentive Earned
            </th>
            <th className="px-3 sm:px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {surveyHistory.map((survey, index) => (
            <tr key={index}>
              <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {new Date(survey.date).toLocaleString(undefined, { 
                  year: 'numeric', 
                  month: 'numeric', 
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </td>
              <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                ${parseFloat(survey.incentive || 0).toFixed(2)}
              </td>
              <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm">
                <span className={`font-medium ${getStatusColor(survey.status)}`}>
                  {survey.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Pagination Controls */}
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-2 sm:px-6 py-3 mt-4">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium ${
            currentPage === 1 ? 'text-gray-300' : 'text-gray-700 hover:bg-gray-50'
          }`}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium ${
            currentPage === totalPages ? 'text-gray-300' : 'text-gray-700 hover:bg-gray-50'
          }`}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <span className="font-medium">
              {surveyHistory.length > 0 ? (currentPage - 1) * 10 + 1 : 0}
            </span>{' '}
            to{' '}
            <span className="font-medium">
              {(currentPage - 1) * 10 + surveyHistory.length}
            </span>{' '}
            of <span className="font-medium">{totalRecords}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                currentPage === 1 ? 'cursor-not-allowed' : ''
              }`}
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  currentPage === index + 1
                    ? 'bg-teal-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600'
                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                currentPage === totalPages ? 'cursor-not-allowed' : ''
              }`}
            >
              Next
            </button>
          </nav>
        </div>
      </div>
    </div>
  </section>
);
};

export default CintSurveyHistory;