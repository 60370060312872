import { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from './DataTable';
import ModelForm from './ModelForm';
import { toast } from 'react-hot-toast';

const ModelManager = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [editingRecords, setEditingRecords] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const excludedFields = [
    'password',
    'user_permissions',
    'groups',
  ];

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    if (selectedModel) {
      fetchRecords(1);
    }
  }, [selectedModel]);

  const fetchModels = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/custom-admin/models/');
      setModels(response.data);
    } catch (error) {
      toast.error('Failed to fetch models');
    } finally {
      setLoading(false);
    }
  };

  const getFieldType = (key, value) => {
    if (key.includes('date') || key.includes('_at')) return 'datetime-local';
    if (key.includes('email')) return 'email';
    if (typeof value === 'boolean') return 'checkbox';
    if (typeof value === 'number') return 'number';
    return 'text';
  };

  const getColumnConfig = (key) => ({
    key,
    label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
    render: (value, row) => {
      if (value === null || value === undefined) return '-';
      if (typeof value === 'boolean') return value ? 'Yes' : 'No';
      if (key.includes('date') || key.includes('_at')) {
        return new Date(value).toLocaleString();
      }
      return String(value);
    }
  });

  const fetchRecords = async (page, search = '') => {
    if (!selectedModel) return;
    
    try {
      setLoading(true);
      const [app, model] = selectedModel.split('.');
      const response = await axios.get(`/api/custom-admin/models/${app}/${model}/`, {
        params: {
          page,
          search
        }
      });
      
      setRecords(response.data.results);
      setPagination({
        currentPage: response.data.current_page,
        totalPages: response.data.total_pages,
        total: response.data.count,
        startIndex: response.data.start_index - 1,
        endIndex: response.data.end_index,
        hasNext: response.data.has_next,
        hasPrevious: response.data.has_previous
      });
    } catch (error) {
      toast.error('Failed to fetch records');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    fetchRecords(newPage, searchTerm);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    fetchRecords(1, term);
  };

  const handleDelete = async (record) => {
    if (!window.confirm('Are you sure you want to delete this record?')) return;
    
    try {
      const [app, model] = selectedModel.split('.');
      await axios.delete(`/api/custom-admin/models/${app}/${model}/${record.id}/`);
      fetchRecords(pagination.currentPage, searchTerm);
      toast.success('Record deleted successfully');
    } catch (error) {
      toast.error('Failed to delete record');
    }
  };

  const handleBulkDelete = async (ids) => {
    if (!window.confirm(`Are you sure you want to delete ${ids.length} records?`)) return;
    
    try {
      const [app, model] = selectedModel.split('.');
      const response = await axios.post(`/api/custom-admin/models/${app}/${model}/bulk-delete/`, { ids });
      
      if (response.data.unsubscribe_results) {
        const failed = response.data.unsubscribe_results.filter(r => !r.mailchimp_unsubscribed);
        if (failed.length > 0) {
          toast.warning(`${failed.length} users could not be unsubscribed from mailing list`);
        }
      }
      
      fetchRecords(pagination.currentPage, searchTerm);
      toast.success(`${response.data.deleted_count} records deleted successfully`);
    } catch (error) {
      toast.error(error.response?.data?.error || 'Failed to delete records');
    }
  };

  const handleAdd = () => {
    setEditingRecord(null);
    setEditingRecords([]);
    setIsFormOpen(true);
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    setEditingRecords([]);
    setIsFormOpen(true);
  };

  const handleBulkEdit = (ids) => {
    const recordsToEdit = records.filter(record => ids.includes(record.id));
    setEditingRecord(null);
    setEditingRecords(recordsToEdit);
    setIsFormOpen(true);
  };

  const handleSubmit = async (formData) => {
    try {
      const [app, model] = selectedModel.split('.');
      
      if (editingRecords.length > 0) {
        const response = await axios.post(`/api/custom-admin/models/${app}/${model}/bulk-update/`, {
          ids: editingRecords.map(r => r.id),
          data: formData
        });
        
        if (response.data.unsubscribe_results) {
          const failed = response.data.unsubscribe_results.filter(r => !r.mailchimp_unsubscribed);
          if (failed.length > 0) {
            toast.warning(`${failed.length} users could not be unsubscribed from mailing list`);
          }
        }
        
        toast.success(`${response.data.updated_count} records updated successfully`);
      } else if (editingRecord) {
        await axios.put(
          `/api/custom-admin/models/${app}/${model}/${editingRecord.id}/`,
          formData
        );
        toast.success('Record updated successfully');
      } else {
        await axios.post(`/api/custom-admin/models/${app}/${model}/`, formData);
        toast.success('Record created successfully');
      }
      
      setIsFormOpen(false);
      fetchRecords(pagination.currentPage, searchTerm);
    } catch (error) {
      toast.error(error.response?.data?.error || 'Operation failed');
    }
  };

  return (
    <div className="space-y-6 min-w-0">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Model Management</h2>
        <select 
          onChange={(e) => setSelectedModel(e.target.value)}
          className="px-4 py-2 border rounded-lg"
          value={selectedModel || ""}
        >
          <option value="">Select a model</option>
          {models.map(model => (
            <option 
              key={`${model.app_label}.${model.name}`} 
              value={`${model.app_label}.${model.name}`}
            >
              {model.verbose_name}
            </option>
          ))}
        </select>
      </div>

      {selectedModel && (
        <DataTable
          data={records}
          columns={records.length > 0 
            ? Object.keys(records[0] || {})
                .filter(key => !excludedFields.includes(key))
                .map(key => getColumnConfig(key))
            : []
          }
          onEdit={handleEdit}
          onDelete={handleDelete}
          onBulkDelete={handleBulkDelete}
          onBulkEdit={handleBulkEdit}
          onAdd={handleAdd}
          isLoading={loading}
          pagination={pagination}
          onPageChange={handlePageChange}
          onSearch={handleSearch}
        />
      )}

      <ModelForm
        isOpen={isFormOpen}
        onClose={() => {
          setIsFormOpen(false);
          setEditingRecord(null);
          setEditingRecords([]);
        }}
        onSubmit={handleSubmit}
        initialData={editingRecord}
        bulkEditData={editingRecords}
        fields={
          records.length > 0
            ? Object.keys(records[0] || {})
                .filter(key => !excludedFields.includes(key))
                .map(key => ({
                  key,
                  label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
                  type: getFieldType(key, records[0][key])
                }))
            : []
        }
      />
    </div>
  );
};

export default ModelManager;