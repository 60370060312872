// src/routes/index.jsx
import { Navigate } from 'react-router-dom';
import App from '../App';
import ErrorPage from '../ErrorPage';
import {
  Home,
  LoginPage,
  PasswordReset,
  PasswordResetConfirm,
  LogoutPage,
  SignupPage,
  PrivacyPage,
  TermsPage,
  AboutPage,
  FaqPage,
  EmailConfirmation,
  Unsubscribe,
  PayPalCallback,
  CintCallback
} from '../pages';

import { dashboardRoutes } from './dashboardRoutes';
import { adminRoutes } from './adminRoutes';

export const routes = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/logout', element: <LogoutPage /> },
      { path: '/signup', element: <SignupPage /> },
      { path: '/password-reset', element: <PasswordReset /> },
      { path: '/password-reset/:uid/:token', element: <PasswordResetConfirm /> },
      { path: '/privacy', element: <PrivacyPage /> },
      { path: '/terms', element: <TermsPage /> },
      { path: '/about', element: <AboutPage /> },
      { path: '/faq', element: <FaqPage /> },
      { path: '/email-confirmation/:key', element: <EmailConfirmation /> },
      { path: '/unsubscribe', element: <Unsubscribe /> },
      { path: '/paypal-callback', element: <PayPalCallback /> },
      { path: '/cint-callback', element: <CintCallback /> },
      ...dashboardRoutes,
      ...adminRoutes
    ],
  },
];