// frontend/src/pages/dashboard/Notifications.jsx
import React from 'react';
import { BellRing } from 'lucide-react';
import {
  FaCog,
  FaPoll,
  FaGift,
  FaUserShield,
  FaBullhorn,
  FaBell,
} from "react-icons/fa";
import { useNotifications } from '../../components/contexts/NotificationContext';

const NotificationList = () => {
  const { notifications, loading, markAsRead, markAllAsRead } = useNotifications();

  if (loading) {
    return <div className="flex justify-center p-8">Loading...</div>;
  }

  const hasUnreadNotifications = notifications.some((n) => !n.is_read);

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-4">
      <div className="flex justify-between items-center">
        {hasUnreadNotifications && (
          <button
            onClick={markAllAsRead}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            Mark all as read
          </button>
        )}
      </div>

      <div className="space-y-4">
        {notifications.length === 0 ? (
          <div className="text-center py-8">
            <BellRing className="mx-auto h-12 w-12 text-gray-400" />
            <p className="mt-2 text-gray-500">No notifications</p>
          </div>
        ) : (
          notifications.map((notification) => (
            <NotificationCard
              key={notification.id}
              notification={notification}
              onMarkAsRead={() => markAsRead(notification.id)}
            />
          ))
        )}
      </div>
    </div>
  );
};

const NotificationCard = ({ notification, onMarkAsRead }) => {
  const { title, message, notification_type, created_at } = notification.notification;
  const isUnread = !notification.is_read;

  // Define icons and styles for different notification types
  const typeStyles = {
    system: {
      icon: <FaCog className="text-blue-500 h-6 w-6" />,
      bgColor: "bg-blue-50",
      borderColor: "border-blue-100",
      hoverColor: "hover:bg-blue-100",
    },
    survey: {
      icon: <FaPoll className="text-green-500 h-6 w-6" />,
      bgColor: "bg-green-50",
      borderColor: "border-green-100",
      hoverColor: "hover:bg-green-100",
    },
    reward: {
      icon: <FaGift className="text-yellow-500 h-6 w-6" />,
      bgColor: "bg-yellow-50",
      borderColor: "border-yellow-100",
      hoverColor: "hover:bg-yellow-100",
    },
    account: {
      icon: <FaUserShield className="text-purple-500 h-6 w-6" />,
      bgColor: "bg-purple-50",
      borderColor: "border-purple-100",
      hoverColor: "hover:bg-purple-100",
    },
    promotion: {
      icon: <FaBullhorn className="text-red-500 h-6 w-6" />,
      bgColor: "bg-red-50",
      borderColor: "border-red-100",
      hoverColor: "hover:bg-red-100",
    },
    default: {
      icon: <FaBell className="text-gray-500 h-6 w-6" />,
      bgColor: "bg-gray-50",
      borderColor: "border-gray-100",
      hoverColor: "hover:bg-gray-100",
    },
  };

  const styles = typeStyles[notification_type] || typeStyles.default;

  return (
    <div
      onClick={onMarkAsRead}
      className={`p-4 rounded-lg border cursor-pointer transition-colors flex gap-4 items-start ${styles.bgColor} ${styles.borderColor} ${styles.hoverColor}`}
    >
      {/* Icon Section */}
      <div>{styles.icon}</div>

      {/* Content Section */}
      <div className="flex-grow">
        <h3 className="font-medium text-lg">{title}</h3>
        <p className="text-gray-600">{message}</p>
        <p className="text-sm text-gray-500 mt-2">
          Sent: {new Date(created_at).toLocaleString()}
        </p>
        {notification.read_at && (
          <p className="text-sm text-gray-400">
            Read: {new Date(notification.read_at).toLocaleString()}
          </p>
        )}
      </div>

      {/* Status Indicator */}
      {isUnread && (
        <span className="h-4 w-4 rounded-full bg-blue-500 mt-2" />
      )}
    </div>
  );
};

export default NotificationList;