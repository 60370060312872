import React, { useState } from 'react';
import axios from 'axios';
import { useMessage } from '../services/MessageService';
import { Link } from 'react-router-dom';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { addMessage } = useMessage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await axios.post('/api/auth/password/reset/', { email });
        setSubmitted(true);
        addMessage('Password reset email sent. Please check your inbox.', 'success');
    } catch (err) {
        const errorMessage = err.response?.data?.detail || 'Failed to send reset email. Please try again.';
        addMessage(errorMessage, 'error');
    }
};

  if (submitted) {
    return (
      <div className="max-w-md mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Check Your Email</h2>
        <p className="text-center mb-4">
          We've sent password reset instructions to {email}.
        </p>
        <p className="text-center text-sm text-gray-600">
          Didn't receive the email? Check your spam folder or{' '}
          <button
            onClick={() => setSubmitted(false)}
            className="text-blue-500 hover:text-blue-600"
          >
            try again
          </button>
        </p>
        <div className="mt-6 text-center">
          <Link to="/login" className="text-blue-500 hover:text-blue-600">
            Back to Login
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Reset Password</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          Send Reset Link
        </button>
      </form>
      <div className="mt-4 text-center">
        <Link to="/login" className="text-blue-500 hover:text-blue-600">
          Back to Login
        </Link>
      </div>
    </div>
  );
}

export default PasswordReset;