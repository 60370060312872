// frontend/src/services/MessageService.jsx
import React, { createContext, useContext, useState } from 'react';

const MessageContext = createContext();

let idCounter = 0;

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addMessage = (content, type) => {
    const newMessage = { id: idCounter++, content, type };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Auto-dismiss message after 5 seconds
    setTimeout(() => removeMessage(newMessage.id), 10000);
  };

  const removeMessage = (id) => {
    setMessages((prevMessages) => prevMessages.filter((message) => message.id !== id));
  };

  return (
    <MessageContext.Provider value={{ messages, addMessage, removeMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
  return context;
};