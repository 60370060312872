import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const RevenueTab = ({ data }) => {
  // console.log("RevenueTab Data:", data); // Debugging log to inspect the received data

  // Destructure data with fallbacks to avoid errors
  const {
    redemptions: {
      daily_data = [],
      total_amount = 0,
      total_count = 0,
      recent_transactions = [],
    } = {},
    revenue_data: {
      rpi = { avg_rpi: 0, total_rpi: 0 },
      user_incentive = { avg_user_incentive: 0, total_user_incentive: 0 },
      net_revenue = { avg_net_revenue: 0, total_net_revenue: 0 },
      trends = [],
    } = {},
  } = data || {};

  return (
    <div className="space-y-6">
      {/* Redemption Activity */}
      <Card>
        <CardHeader>
          <CardTitle>Redemption Activity</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={daily_data}
                margin={{ top: 20, right: 30, left: 60, bottom: 40 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={(date) => new Date(date).toLocaleDateString()}
                  label={{ value: 'Date', position: 'bottom', offset: 0 }}
                />
                <YAxis
                  label={{ value: 'Amount ($)', angle: -90, position: 'left', offset: 10 }}
                />
                <Tooltip
                  labelFormatter={(date) => new Date(date).toLocaleDateString()}
                  formatter={(value) => [`$${value?.toFixed(2)}`, 'Amount']}
                />
                <Line
                  type="monotone"
                  dataKey="total_amount"
                  stroke="#2563eb"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      {/* Revenue Trends */}
      <Card>
        <CardHeader>
          <CardTitle>Revenue Trends</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={trends}
                margin={{ top: 20, right: 30, left: 60, bottom: 40 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={(date) => new Date(date).toLocaleDateString()}
                  label={{ value: 'Date', position: 'bottom', offset: 0 }}
                />
                <YAxis
                  label={{ value: 'Amount ($)', angle: -90, position: 'left', offset: 10 }}
                />
                <Tooltip
                  labelFormatter={(date) => new Date(date).toLocaleDateString()}
                  formatter={(value) => [`$${value?.toFixed(2)}`, 'Amount']}
                />
                <Line type="monotone" dataKey="daily_rpi" stroke="#2563eb" name="RPI" />
                <Line type="monotone" dataKey="daily_user_incentive" stroke="#22c55e" name="User Incentive" />
                <Line type="monotone" dataKey="daily_net_revenue" stroke="#f97316" name="Net Revenue" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      {/* Revenue Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>RPI Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <div>
                <div className="text-sm font-medium text-gray-500">Average RPI</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${rpi.avg_rpi?.toFixed(2) || '0.00'}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Total RPI</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${rpi.total_rpi?.toFixed(2) || '0.00'}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>User Incentive Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <div>
                <div className="text-sm font-medium text-gray-500">Average Incentive</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${user_incentive.avg_user_incentive?.toFixed(2) || '0.00'}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Total Incentive</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${user_incentive.total_user_incentive?.toFixed(2) || '0.00'}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Net Revenue Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <div>
                <div className="text-sm font-medium text-gray-500">Average Revenue</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${net_revenue.avg_net_revenue?.toFixed(2) || '0.00'}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Total Revenue</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${net_revenue.total_net_revenue?.toFixed(2) || '0.00'}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default RevenueTab;