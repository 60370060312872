import React, { useState } from 'react';
import axios from 'axios';
import { useMessage } from '../../services/MessageService';

function Subscribe() {
  const { addMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [mailchimpStatus, setMailchimpStatus] = useState(null);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('/api/subscribe/', {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access-token')}` },
      });

      if (response.status === 200) {
        setSubscribed(true);
        setMailchimpStatus(response.data.mailchimp_updated);
        addMessage("You have successfully subscribed!", "success");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 
        "An error occurred while processing your subscription. Please try again.";
      addMessage(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto my-10 p-6 bg-white rounded shadow-md">
      <div className="max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4">Subscribe to Emails</h2>
        <p>Resubscribe to receive updates and communications.</p>
        {subscribed ? (
          <div className="space-y-4">
            <p className="text-green-600">
              You have successfully subscribed!
            </p>
            {mailchimpStatus === false && (
              <p className="text-yellow-600">
                However, there was an issue updating the mailing list. Please contact support if needed.
              </p>
            )}
          </div>
        ) : (
          <form onSubmit={handleSubscribe} className="space-y-4">
            <div>
              <button
                type="submit"
                disabled={loading}
                className={`w-full py-2 px-4 rounded-lg text-white ${
                  loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
                }`}
              >
                {loading ? 'Processing...' : 'Subscribe'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Subscribe;