import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Import all tab components
import GrowthTab from './analyticsTabs/GrowthTab';
import RevenueTab from './analyticsTabs/RevenueTab';
import PerformanceTab from './analyticsTabs/PerformanceTab';
import EngagementTab from './analyticsTabs/EngagementTab';
import SurveyOutcomesTab from './analyticsTabs/SurveyOutcomesTab';

const AdminAnalytics = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('growth');
  const [filters, setFilters] = useState({
    date_range: '30d',
  });

  const tabs = [
    { id: 'growth', label: 'User Growth' },
    { id: 'revenue', label: 'Revenue & Redemptions' },
    { id: 'performance', label: 'Platform Performance' },
    { id: 'engagement', label: 'User Engagement' },
    { id: 'survey-outcomes', label: 'Survey Outcomes' }
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const params = new URLSearchParams(filters);
      const response = await axios.get(`/api/custom-admin/analytics/?${params}`);
      // console.log('Analytics response:', response.data); // Debug log
      setData(response.data);
    } catch (error) {
      // console.error('Analytics error:', error); // Debug log
      setError(error.response?.data?.detail || 'Failed to fetch analytics data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const renderTabContent = () => {
    try {
      switch (activeTab) {
        case 'growth':
          return <GrowthTab data={data?.signups} />;
        case 'revenue':
          return (
            <RevenueTab
              data={{
                ...data?.redemptions,
                revenue_data: data?.revenue_data || {
                  rpi: { avg_rpi: 0, total_rpi: 0 },
                  user_incentive: { avg_user_incentive: 0, total_user_incentive: 0 },
                  net_revenue: { avg_net_revenue: 0, total_net_revenue: 0 },
                  trends: [],
                },
              }}
            />
          );
        case 'performance':
          return <PerformanceTab data={{
            user_performance: data?.user_performance || {
              avg_level: 0,
              avg_incentive: 0,
              total_completions: 0,
              level_distribution: []
            },
            survey_metrics: data?.survey_metrics || {
              avg_cpi: 0,
              total_surveys: 0
            }
          }} />;
        case 'engagement':
          return <EngagementTab data={data?.user_engagement || {
            total_logins: 0,
            average_session_time: 0,
            active_users_24h: 0,
            active_users_7d: 0,
            active_users_30d: 0,
            current_login_streaks: [],
            longest_streaks: []
          }} />;
        case 'survey-outcomes':
          return <SurveyOutcomesTab data={data?.survey_outcomes || {
            daily_completion_rates: [],
            marketplace_status_dist: [],
            client_status_dist: [],
            metrics: {
              overall_completion_rate: 0,
              avg_revenue_per_survey: 0,
              total_completed_surveys: 0,
              quality_term_rate: 0,
              screener_pass_rate: 0,
              client_survey_pass_rate: 0
            }
          }} />;
        default:
          return null;
      }
    } catch (err) {
      // console.error('Error rendering tab:', err);
      return (
        <div className="p-4 bg-red-50 border border-red-200 rounded">
          <p className="text-red-700">Error displaying this tab. Please try again later.</p>
        </div>
      );
    }
  };

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
        <p className="font-bold">Error Loading Analytics</p>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 border-b space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                  activeTab === tab.id
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <select
            className="rounded-md border border-gray-300 p-2"
            value={filters.date_range}
            onChange={(e) => setFilters({ ...filters, date_range: e.target.value })}
          >
            <option value="7d">Last 7 Days</option>
            <option value="30d">Last 30 Days</option>
            <option value="90d">Last 90 Days</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="text-gray-500">Loading...</div>
        </div>
      ) : (
        <div className="p-4">
          {renderTabContent()}
        </div>
      )}
    </div>
  );
};

export default AdminAnalytics;