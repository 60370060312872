// frontend/src/providers/AppProviders.jsx
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import AuthProvider from '../auth/authprovider';
import { MessageProvider } from '../services/MessageService';
import { NotificationProvider } from '../components/contexts/NotificationContext';

export const AppProviders = ({ children, store }) => {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <AuthProvider>
          <MessageProvider>
            <NotificationProvider>
              {children}
            </NotificationProvider>
          </MessageProvider>
        </AuthProvider>
      </ReduxProvider>
    </React.StrictMode>
  );
};