// frontend/src/auth/authcontext.js
import { createContext } from 'react';

export const AuthContext = createContext({
  token: null,
  user: null,
  isAuthenticated: false,
  isLoading: true,
  isSuperuser: false,
  hasUsablePassword: false,
  isAnonymous: false,
  setUserDetails: () => {},
  logout: () => {},
  getToken: () => null,
});