import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../auth/authcontext';
import { useMessage } from '../services/MessageService';
import axios from 'axios';

const CintCallback = () => {
  const { token } = useContext(AuthContext);
  const { addMessage } = useMessage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [processing, setProcessing] = useState(true);
  const processedRef = useRef(false);
  const location = useLocation();

  //NOTE: Put these into an admin panel for customization later
  const successMessages = [
    "Great job! Your complete has been captured successfully.",
    "Keep Going! Survey complete! Data logged and secured.",
    "Early bird gets the worm! Keep up the good work!",
    "A complete a day keeps the doctor away! Seriously, great job!",
    "Impressive! Survey complete! Keep up the good work!"
  ];

  //NOTE: Put these into an admin panel for customization later
  const terminationMessages = [
    "Darn it! Survey terminated. No worries, try again!",
    "You must have hit a full quota for this survey. Try again later!",
    "Remember that quality is key!",
    "Quality over quantity! Try again! But seriously, don't rush!",
    "Make sure to read the questions carefully! Try again!",
    "Don't give up! Try again! Remember, quality is key!",
    "Well shoot! Don't worry this is normal. Try again!",
    "Don't worry. We still love you! Try again!",
  ];  

  const getRandomMessage = (messages) => {
    const index = Math.floor(Math.random() * messages.length);
    return messages[index];
  };
  
  useEffect(() => {
    let isSubscribed = true;
    const currentPath = location.pathname + location.search;
    const storedPath = sessionStorage.getItem('currentCintCallback');

    // If we've already processed this exact URL, redirect to login if no auth
    if (storedPath === currentPath) {
      const storedToken = sessionStorage.getItem('authToken') || token;
      if (storedToken) {
        navigate('/dashboard/survey-entry', { replace: true });
      } else {
        navigate('/login', { replace: true });
      }
      return;
    }

    const handleCintCallback = async () => {
      if (processedRef.current) {
        return;
      }

      const params = new URLSearchParams(searchParams);
      const queryString = params.toString();

      if (!queryString.includes('pid') || !queryString.includes('hash')) {
        if (isSubscribed) {
          addMessage('Survey callback failed. Missing required parameters.', 'error');
          navigate('/login', { replace: true });
        }
        return;
      }

      try {
        processedRef.current = true;
        sessionStorage.setItem('currentCintCallback', currentPath);

        // Process callback without authorization header
        let retryCount = 0;
        const maxRetries = 2;
        
        while (retryCount <= maxRetries) {
          try {
            const response = await axios.get(`/api/integrations/cint-callback/?${queryString}`);

            if (response.data.status === "success") {
              if (isSubscribed) {
                const message =
                  response.data.redirect_type === "Complete"
                    ? getRandomMessage(successMessages)
                    : getRandomMessage(terminationMessages);
                addMessage(message, "success");
            
                // Check auth and redirect accordingly
                const storedToken = sessionStorage.getItem("authToken") || token;
                if (storedToken) {
                  navigate("/dashboard/survey-entry", { replace: true });
                } else {
                  navigate("/login", { replace: true });
                }
              }
              return;
            }            
            break;
          } catch (error) {
            if (error.response?.status === 409) {
              // Already processed - redirect based on auth status
              const storedToken = sessionStorage.getItem('authToken') || token;
              if (storedToken) {
                navigate('/dashboard/survey-entry', { replace: true });
              } else {
                navigate('/login', { replace: true });
              }
              return;
            }
            if (retryCount === maxRetries) throw error;
            retryCount++;
            await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
          }
        }

        // If we get here without success or a 409, show error and redirect to login
        addMessage('Failed to process survey callback. Please try again.', 'error');
        navigate('/login', { replace: true });

      } catch (error) {
        if (!isSubscribed) return;

        const errorMessage = error.response?.data?.reason || 'Failed to process survey callback.';
        addMessage(errorMessage, 'error');
        navigate('/login', { replace: true });
      }
    };

    handleCintCallback();

    return () => {
      isSubscribed = false;
    };
  }, [token, searchParams, navigate, addMessage, location]);

  if (processing) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-4">Processing Survey Completion</h2>
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4 text-gray-600">Please wait while we process your survey completion...</p>
        </div>
      </div>
    );
  }

  return null;
};

export default CintCallback;