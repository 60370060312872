// src/components/Header.jsx
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from "../auth/authcontext";
import NotificationBell from './NotificationBell';
import ErrorBoundary from './ErrorBoundary';
import logo from '../assets/images/LEVELREV-logo.svg';
import logoGray from '../assets/images/LEVELREV-logo-gray.svg';

const Header = () => {
  const { user, isAuthenticated, isLoading, logout } = useContext(AuthContext);

  return (
    <header className="pt-4 text-white">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-lg font-bold">
          <Link to="/" className="block w-[200px] relative group">
            <img
              src={logo}
              alt="LevelRev Logo"
              className="w-full transition-all duration-200 group-hover:scale-105 group-hover:opacity-0"
            />
            <img
              src={logoGray}
              alt="LevelRev Logo Hover"
              className="w-full transition-all duration-200 group-hover:scale-105 absolute top-0 left-0 opacity-0 group-hover:opacity-75"
            />
          </Link>
        </div>
        <nav className="flex items-center gap-4">
          {isLoading ? (
            <p>Loading...</p>
          ) : isAuthenticated ? (
            <>
              <NotificationBell />
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                Logout
              </Link>
            </>
          ) : (
            <>
              <Link to="/signup">Sign Up</Link>
              <Link to="/login">Login</Link>
            </>
          )}
        </nav>

      </div>
    </header>
  );
};

export default Header;