// components/LevelUpProgress.jsx
import React from 'react';

const LevelUpProgress = ({
  userLevel,
  surveysNeeded,
  nextLevel,
  progressPercentage,
  className = '',
  headingTag = 'h2'
}) => {
  const HeadingTag = headingTag;

  return (
    <div className={`bg-white rounded-lg shadow p-6 ${className}`}>
      <HeadingTag className="text-2xl font-semibold">LevelUp Progress</HeadingTag>
      {userLevel < 7 ? (
        <p className="text-gray-600 mb-2">
          {surveysNeeded} more completes to reach Level {nextLevel}
        </p>
      ) : (
        <p className="text-gray-600 mb-2">
          Congratulations! You've reached the highest level.
        </p>
      )}
      <div className="relative w-full bg-gray-200 h-4 rounded-full mt-3 overflow-hidden">
        <div
          className="bg-seafoam-green h-full rounded-full transition-all duration-500"
          style={{ width: `${progressPercentage || 0}%` }}
        ></div>
      </div>
    </div>
  );
};

export default LevelUpProgress;