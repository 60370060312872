import React, { useState } from 'react';
import axios from 'axios';
import { useMessage } from '../services/MessageService';

function Unsubscribe() {
  const { addMessage } = useMessage();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [mailchimpStatus, setMailchimpStatus] = useState(null);

  const handleUnsubscribe = async (e) => {
    e.preventDefault();

    if (!email) {
      addMessage("Please provide your email address to unsubscribe.", "error");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post('/api/auth/unsubscribe/', { email });

      if (response.status === 200) {
        setUnsubscribed(true);
        setMailchimpStatus(response.data.mailchimp_updated);
        
        if (response.data.mailchimp_updated) {
          addMessage("You have been successfully unsubscribed from all communications.", "success");
        } else {
          addMessage(
            "You have been unsubscribed from our database, but there was an issue updating the mailing list. " +
            "Please contact support if you continue to receive emails.", 
            "warning"
          );
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 
        "An error occurred while processing your unsubscribe request. Please try again.";
      addMessage(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto my-10 p-6 bg-white rounded shadow-md">
      <div className="max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4">Unsubscribe from Emails</h2>
        <p>
          Unsubscribing removes you from our email list, but your LevelRev account will still exist.
        </p>
        {unsubscribed ? (
          <div className="space-y-4">
            <p className="text-green-600">
              You have successfully unsubscribed from our database.
            </p>
            {mailchimpStatus === false && (
              <p className="text-yellow-600">
                However, there was an issue updating the mailing list. If you continue 
                to receive emails, please contact our support team.
              </p>
            )}
            <p className="text-gray-600 mt-4">
              We're sorry to see you go! If you change your mind, you can always 
              resubscribe from your account settings.
            </p>
          </div>
        ) : (
          <form onSubmit={handleUnsubscribe} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email address"
              />
            </div>
            <div>
              <button
                type="submit"
                disabled={loading}
                className={`w-full py-2 px-4 rounded-lg text-white ${
                  loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
                }`}
              >
                {loading ? 'Processing...' : 'Unsubscribe'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Unsubscribe;