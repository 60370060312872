import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link, Form, useSubmit } from 'react-router-dom';
import { useMessage } from '../services/MessageService';
import { useSelector } from 'react-redux';
import { CountryChoices } from '../dropdowns/dropdownChoices';

const SignupForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password1: '',
    password2: '',
    country: '',
    terms_accepted: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const navigate = useNavigate();
  const submit = useSubmit();
  const { addMessage } = useMessage();
  const utmData = useSelector((state) => state.utm);

  const validateUsername = (username) => {
    // Must start with a letter
    if (!/^[a-zA-Z]/.test(username)) {
      return "Username must start with a letter";
    }

    // Only allow letters, numbers, and certain special characters
    if (!/^[a-zA-Z][a-zA-Z0-9_.-]*$/.test(username)) {
      return "Username can only contain letters, numbers, and the characters: _ - .";
    }

    // Check for repetitive characters
    if (/(.)(\1{3,})/.test(username)) {
      return "Username cannot contain repetitive characters";
    }

    // Check for excessive numbers
    if ((username.match(/\d/g) || []).length > username.length / 2) {
      return "Username cannot contain too many numbers";
    }

    return "";
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    // Clear username error when user starts typing again
    if (name === 'username') {
      setUsernameError(validateUsername(value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    // Validate username before submitting
    const usernameValidationError = validateUsername(formData.username);
    if (usernameValidationError) {
      setUsernameError(usernameValidationError);
      return;
    }

    if (!formData.terms_accepted) {
      addMessage('Please accept the Terms of Service and Privacy Policy to continue.', 'error');
      return;
    }
    
    setIsSubmitting(true);

    try {
      const registrationData = {
        ...formData,
        utm_data: utmData
      };

      const makeRequest = async (attempt = 1) => {
        try {
          return await axios.post('/api/auth/registration/', registrationData, {
            timeout: 15000,
          });
        } catch (error) {
          if (attempt < 2 && !error.response) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            return makeRequest(attempt + 1);
          }
          throw error;
        }
      };

      await makeRequest();
      addMessage('Registration successful! Please check your email to verify your account.', 'success');
      window.dataLayer.push({ 'event': 'signup_success', ...utmData });
      navigate('/login?signup_success=true');

    } catch (err) {
      if (err.message?.includes('timeout')) {
        addMessage('The request timed out. Please check your connection and try again. If you are using a VPN, you may want to temporarily disable it.', 'error');
      } else if (err.response?.data) {
        const errorData = err.response.data;
        Object.entries(errorData).forEach(([key, messages]) => {
          if (Array.isArray(messages)) {
            messages.forEach(message => addMessage(`${key}: ${message}`, 'error'));
          } else if (typeof messages === 'string') {
            addMessage(`${key}: ${messages}`, 'error');
          }
        });
      } else {
        addMessage('An unexpected error occurred. Please try again later.', 'error');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-6">
      <Form 
        onSubmit={handleSubmit} 
        className="space-y-4"
        method="POST"
        action="/api/auth/registration/"
      >
        <div>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Username"
            required
            disabled={isSubmitting}
            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              usernameError ? 'border-red-500' : ''
            }`}
          />
          {usernameError && (
            <p className="mt-1 text-sm text-red-500">{usernameError}</p>
          )}
        </div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          name="password1"
          value={formData.password1}
          onChange={handleChange}
          placeholder="Password"
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          name="password2"
          value={formData.password2}
          onChange={handleChange}
          placeholder="Confirm Password"
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        
        <select
          name="country"
          value={formData.country}
          onChange={handleChange}
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select Country</option>
          {Object.entries(CountryChoices).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>

        <div className="flex items-start space-x-2 text-sm">
          <input
            type="checkbox"
            name="terms_accepted"
            id="terms_accepted"
            checked={formData.terms_accepted}
            onChange={handleChange}
            className="mt-1"
            required
          />
          <label htmlFor="terms_accepted" className="text-gray-600">
            I agree to the{' '}
            <Link to="/terms" className="text-blue-500 hover:text-blue-600">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link to="/privacy" className="text-blue-500 hover:text-blue-600">
              Privacy Policy
            </Link>
          </label>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="btn-primary w-full text-white py-2 rounded-lg transition duration-200 disabled:opacity-50"
        >
          {isSubmitting ? 'Signing Up...' : 'Sign Up'}
        </button>
      </Form>
    </div>
  );
};

export default SignupForm;