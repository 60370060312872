// frontend/src/components/Login.jsx

import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../auth/authcontext';
import { useMessage } from '../services/MessageService';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUserDetails } = useContext(AuthContext);
  const { addMessage } = useMessage();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login/', { email, password });
      setUserDetails(response.data);
      
      addMessage("Successfully logged in!", "success");
      navigate('/dashboard/survey-entry');
    } catch (err) {
      const errorMessage = err.response?.data?.non_field_errors?.[0] || 'Login failed. Please check your credentials.';
      addMessage(errorMessage, 'error');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
      <form onSubmit={handleLogin} className="space-y-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          Login
        </button>
      </form>
      <div className="mt-4 text-center">
        <Link to="/password-reset" className="text-blue-500 hover:text-blue-600">
          Forgot Password?
        </Link>
      </div>
    </div>
  );
}

export default LoginPage;