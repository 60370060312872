import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const PerformanceTab = ({ data }) => {
  const {
    user_performance = {
      avg_level: 0,
      avg_incentive: 0,
      total_completions: 0,
      level_distribution: []
    },
    survey_metrics = {
      avg_cpi: 0,
      total_surveys: 0
    }
  } = data || {};

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>User Level Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart 
                  data={user_performance.level_distribution}
                  margin={{ top: 20, right: 30, left: 60, bottom: 40 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="user_level"
                    label={{ value: 'User Level', position: 'bottom', offset: 0 }}
                  />
                  <YAxis 
                    label={{ value: 'Number of Users', angle: -90, position: 'left', offset: 10 }}
                  />
                  <Tooltip />
                  <Bar dataKey="count" fill="#2563eb" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Survey Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div>
                <div className="text-sm font-medium text-gray-500">Average CPI</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${survey_metrics.avg_cpi?.toFixed(2) || '0.00'}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Total Surveys Completed</div>
                <div className="mt-1 text-2xl font-semibold">
                  {user_performance.total_completions?.toLocaleString() || 0}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Average User Level</div>
                <div className="mt-1 text-2xl font-semibold">
                  {user_performance.avg_level?.toFixed(1) || '0.0'}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default PerformanceTab;